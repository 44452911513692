import React, { useState, useEffect } from "react";
import axios from "axios";
import base from "../../../Config/config";
import { Link, useParams } from "react-router-dom";
import collegeLogo1 from "../../../Assets/Images/uni-logo.jpg";
import adrianbanner from "../../../Assets/Images/adrian-banner.png";
import handshake from "../../../Assets/Images/handshake-icon.png";
import Navbar from "../../../Components/Navbar/Navbar";
import Sidebar from "../../../Components/Sidebar/sidebar";
import { CircularProgress } from "@mui/material";
import "./collegedetails.scss";
import "../College/college.scss";

const CollegeDetail = () => {
  const [university, setUniversity] = useState([]);
  const [loading, setLoading] = useState(true);

  const name = localStorage.getItem("name");

  const { collegeId } = useParams();

  useEffect(() => {
    const fetchCollege = async () => {
      try {
        const token = localStorage.getItem("token");
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        const response = await axios.get(
          `${base.BASE_URL}/university/get?_id=${collegeId}`,
          config
        );
        setUniversity(response.data.data.universities);
        console.log(response.data.data.universities);
      } catch (error) {
        console.error("Error fetching profile data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCollege();
  }, []);

  // const college = cardsData.find(
  //   (college) => college.id === parseInt(collegeId)
  // );

  if (loading) {
    return (
      <div
        className="loader-container d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <CircularProgress />
      </div>
    );
  } else if (university) {
    return (
      <div className="college-parti">
        <div className="container-fluid">
          <Navbar />
          <div className="row">
            <div className="col-lg-2">
              <Sidebar />
            </div>
            <div className="col-lg-10">
              <div className="row">
                <div className="college-banner">
                  <div className="college-banner-img">
                    <img src={university[0].bannerImage} alt="college banner" />
                  </div>

                  <div className="college-banner-content">
                    <div className="college-banner-content-img">
                      <img src={university[0].displayPicture} alt="college" />
                    </div>
                    <div className="college-banner-content-text">
                      {
                        university[0].general?.school?(<h2>{university[0].general.school}</h2>):(<h2>No School Name</h2>)
                      }
                      {/* <h2>{university[0].general.school}</h2> */}
                      <p>
                        {university[0]?.general?.city},
                        {university[0]?.general?.state},
                        {university[0]?.general?.locale}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="general-wrap">
                    <div className="genral-head">
                      <h3>General Information</h3>
                    </div>
                    <div className="general-content">
                      <div className="general-content-skills">
                        <ul>
                          <li>
                            <h4>Type:</h4>
                            <p>{university[0]?.general.type}</p>
                          </li>
                          <li>
                            <h4>Website:</h4>
                            {university[0]?.general.website === "-" ? (
                              <p>No available link</p>
                            ) : (
                              <a
                                href={university[0]?.general.website}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <p>{university[0]?.general.website}</p>
                              </a>
                            )}
                          </li>

                          <li>
                            <h4>Team Twitter Url:</h4>
                            {university[0]?.general.team_twitter === "-" ? (
                              <p>No available link</p>
                            ) : (
                              <a
                                href={university[0]?.general.team_twitter}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <p>{university[0]?.general.team_twitter}</p>
                              </a>
                            )}
                          </li>
                          <li>
                            <h4>Team Instagram Url:</h4>
                            {university[0]?.general.team_instagram === "-" ? (
                              <p>No available link</p>
                            ) : (
                              <a
                                href={university[0]?.general.team_instagram}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <p>{university[0]?.general.team_instagram}</p>
                              </a>
                            )}
                          </li>
                          <li>
                            <h4>Team Facebook Url:</h4>
                            {university[0].general.team_facebook === "-" ? (
                              <p>No available link</p>
                            ) : (
                              <a
                                href={university[0]?.general.team_facebook}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <p>{university[0].general.team_facebook}</p>
                              </a>
                            )}
                          </li>
                          <li>
                            <h4>Location:</h4>
                            <p>
                              {university[0]?.general.city +
                                "," +
                                university[0]?.general.state +
                                "," +
                                university[0]?.general.locale}
                            </p>
                          </li>
                          <li>
                            <h4>Locale:</h4>
                            <p>{university[0]?.general.locale}</p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="academic-wrap">
                    <div className="academic-head">
                      <h3>Academic Information</h3>
                    </div>
                    <div className="academic-content">
                      <div className="academic-content-skills">
                        {console.log(university[0]?.academics)}
                        <ul>
                          {university[0]?.academics?.map((academic) => {
                            return (
                              <>
                                <li>
                                  <h4>Level:</h4>
                                  <p>{academic?.level}</p>
                                </li>
                                <li>
                                  <h4>Predominant Degree:</h4>
                                  <p>{academic?.predominant_degree}</p>
                                </li>
                              </>
                            );
                          })}
                        </ul>
                        <div className="field-study">
                          <h4>Field of Study:</h4>
                          <div className="fields">
                            {university[0]?.academics?.map((fields) => {
                              return fields.fields_of_study.map((field) => {
                                return <span>{field}</span>;
                              });
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="athletics-wrap">
                    <div className="athletics-head">
                      <h3>Athletics</h3>
                    </div>
                    <div className="athletics-content">
                      <div className="athletics-content-skills">
                        <ul>
                          <li>
                            <h4>Division:</h4>
                            <p>{university[0].athletics.division}</p>
                          </li>
                          <li>
                            <h4>Conference:</h4>
                            <p>{university[0].athletics.conference}</p>
                          </li>
                          <li>
                            <h4>Athletic Website:</h4>
                            {university[0].athletics.team_facebook === "-" ? (
                              <p>No available link</p>
                            ) : (
                              <a
                                href={university[0].athletics.athletic_website}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <p>
                                  {university[0].athletics.athletic_website}
                                </p>
                              </a>
                            )}
                          </li>
                          <li>
                            <h4>Team Website:</h4>
                            {university[0].athletics.team_website === "-" ? (
                              <p>No available link</p>
                            ) : (
                              <a
                                href={university[0].athletics.team_website}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <p>{university[0].athletics.team_website}</p>
                              </a>
                            )}
                          </li>
                          <li>
                            <h4>Questionnaire:</h4>
                            {university[0].athletics.sports_offered === "-" ? (
                              <p>No available link</p>
                            ) : (
                              <a
                                href={university[0].athletics.questionnaire}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <p>{university[0].athletics.questionnaire}</p>
                              </a>
                            )}
                          </li>
                        </ul>
                        <div className="sports-offer">
                          <h4>Sports Offered:</h4>
                          <div className="fields">
                            {university[0].athletics.sports_offered.map(
                              (sport) => {
                                return <span>{sport}</span>;
                              }
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="myathletes-wrap">
                    <div className="myathletes-head">
                      <h3>Coaches</h3>
                      <p>
                        The below athletes have an active recruiting process
                        with this program.
                      </p>
                    </div>
                    {university[0].coaches.map((coach) => {
                      return (
                        <div className="athlete-person">
                          <div className="about-athlete">
                            <div className="athlete-person-img">
                              <img src={collegeLogo1} alt="college" />
                            </div>
                            <div className="athlete-person-text">
                              <h4>
                                {coach.first_name} {coach.last_name}
                              </h4>
                              <p>
                                {coach.position} • {coach.sports}
                              </p>
                              <p>
                                {coach.email_address} • {coach.phone_number}
                              </p>
                              {university[0].coaches.individual_twitter ===
                              "-" ? (
                                <p>No available link</p>
                              ) : (
                                <a
                                  href={
                                    university[0].coaches.individual_twitter
                                  }
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <p>
                                    {university[0].coaches.individual_twitter}
                                  </p>
                                </a>
                              )}

                              {university[0].coaches.individual_facebook ===
                              "-" ? (
                                <p>No available link</p>
                              ) : (
                                <a
                                  href={
                                    university[0].coaches.individual_facebook
                                  }
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <p>
                                    {university[0].coaches.individual_facebook}
                                  </p>
                                </a>
                              )}

                              {university[0].coaches.individual_instagram ===
                              "-" ? (
                                <p>No available link</p>
                              ) : (
                                <a
                                  href={
                                    university[0].coaches.individual_instagram
                                  }
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <p>
                                    {university[0].coaches.individual_instagram}
                                  </p>
                                </a>
                              )}
                            </div>
                          </div>
                          <div className="interest-btn-wrap">
                            <p>
                              <span>Interested</span>
                            </p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-lg-12">
                  <div className="football-coaches-head">
                    <h4>Football Coaches</h4>
                    <div className="couches-tabs">
                      <ul>
                        <li>Head Coach</li>
                        <li>Assistant Coaches</li>
                      </ul>
                    </div>
                  </div>
                  <div className="head-couches-cards-wrapper couches-cards-wrapper">
                    <div className="row couches-card-wrap">
                      <div className="col-lg-3">
                        <div className="couches-card">
                          <div className="couches-card-img">
                            <img src={collegeLogo1} alt="college" />
                          </div>
                          <div className="couches-card-text">
                            <h4>Kurt Britnell</h4>
                            <p>
                              Volunteer Assistant Coach <br />
                              (Tight Ends..)
                            </p>
                          </div>
                          <div className="interaction-btns-wrapper">
                            <div className="interaction-btns-wrap">
                              <div className="message-btn int-btn">
                                <i
                                  className="fa fa-envelope"
                                  aria-hidden="true"
                                ></i>
                              </div>
                              <div className="phone-btn int-btn">
                                <i
                                  className="fa fa-phone"
                                  aria-hidden="true"
                                ></i>
                              </div>
                              <div className="twitter-btn int-btn">
                                <i
                                  className="fa fa-twitter"
                                  aria-hidden="true"
                                ></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="metrics-wrapper">
                <div className="row mt-4">
                  <div className="col-lg-12">
                    <h4>Metrics</h4>
                  </div>
                  <div className="col-lg-4 my-3">
                    <div className="metrics-card">
                      <div className="metrics-card-img">
                        <img src={handshake} alt="college" />
                      </div>
                      <div className="metrics-card-content">
                        <h4>Graduation Rate</h4>
                        <p>{university[0].metrics.graduation_rate}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 my-3">
                    <div className="metrics-card">
                      <div className="metrics-card-img">
                        <img src={handshake} alt="college" />
                      </div>
                      <div className="metrics-card-content">
                        <h4>Acceptance Rate</h4>
                        <p>{university[0].metrics.acceptance_rate}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 my-3">
                    <div className="metrics-card">
                      <div className="metrics-card-img">
                        <img src={handshake} alt="college" />
                      </div>
                      <div className="metrics-card-content">
                        <h4>Undergraduate Enrollment</h4>
                        <p>{university[0].metrics.undergraduate_enrollment}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 my-3">
                    <div className="metrics-card">
                      <div className="metrics-card-img">
                        <img src={handshake} alt="college" />
                      </div>
                      <div className="metrics-card-content">
                        <h4>Act Score Composite</h4>
                        <p>{university[0].metrics.act_score_composite}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 my-3">
                    <div className="metrics-card">
                      <div className="metrics-card-img">
                        <img src={handshake} alt="college" />
                      </div>
                      <div className="metrics-card-content">
                        <h4>Average Gpa</h4>
                        <p>{university[0].metrics.average_gpa}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 my-3">
                    <div className="metrics-card">
                      <div className="metrics-card-img">
                        <img src={handshake} alt="college" />
                      </div>
                      <div className="metrics-card-content">
                        <h4>Average Sat Math Score</h4>
                        <p>{university[0].metrics.average_sat_math_score}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 my-3">
                    <div className="metrics-card">
                      <div className="metrics-card-img">
                        <img src={handshake} alt="college" />
                      </div>
                      <div className="metrics-card-content">
                        <h4>Average Sat Math Score</h4>
                        <p>{university[0].metrics.average_sat_reading_score}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="college-parti">
        <div className="container-fluid">
          <Navbar />
          <div className="row">
            <div className="col-lg-2">
              <Sidebar />
            </div>
            <div className="col-lg-10">
              <div className="row">No College info Available</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default CollegeDetail;
