// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loginheader {
  position: absolute;
  width: 100vw;
  padding: 20px;
  z-index: 11;
}
.loginheader .links-wrapper ul {
  display: flex;
  gap: 30px;
  align-items: center;
  margin-bottom: 0px;
}
.loginheader .links-wrapper ul li {
  list-style: none;
  color: #fff;
}
.loginheader .links-wrapper ul li span {
  margin-left: 5px;
}
.loginheader .links-wrapper ul li a {
  text-decoration: none;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
}
.loginheader .links-wrapper ul li a:hover {
  color: #007bff;
}`, "",{"version":3,"sources":["webpack://./src/Components/LoginHeader/loginheader.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,YAAA;EACA,aAAA;EACA,WAAA;AACJ;AACQ;EACI,aAAA;EACA,SAAA;EACA,mBAAA;EACA,kBAAA;AACZ;AAAY;EACI,gBAAA;EACA,WAAA;AAEhB;AADe;EACC,gBAAA;AAGhB;AADgB;EACI,qBAAA;EACA,WAAA;EACA,eAAA;EACA,gBAAA;AAGpB;AAFoB;EACI,cAAA;AAIxB","sourcesContent":[".loginheader{\n    position: absolute;\n    width: 100vw;\n    padding: 20px;\n    z-index: 11;\n    .links-wrapper{\n        ul{\n            display: flex;\n            gap: 30px;\n            align-items: center;\n            margin-bottom: 0px;\n            li{\n                list-style: none;\n                color: #fff;\n               span{\n                margin-left: 5px;\n               }\n                a{\n                    text-decoration: none;\n                    color: #fff;\n                    font-size: 14px;\n                    font-weight: 500;\n                    &:hover{\n                        color: #007bff;\n                    }\n                }\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
