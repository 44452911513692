const initialState = {
  profileInfo: null,
  loadingprofile: false,
  errorprofile: null
};

const athleteInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case "PROFILEINFO_REQUEST":
      return {
        ...state,
        loadingprofile: true,
        errorprofile: null
      };
    case "PROFILEINFO_SUCCESS":
      return {
        ...state,
        loadingprofile: false,
        profileInfo: action.payload,
        errorprofile: null
      };
    case "PROFILEINFO_FAILURE":
      return {
        ...state,
        loadingprofile: false,
        profileInfo: null,
        errorprofile: action.payload
      };
    default:
      return state;
  }
};

export default athleteInfoReducer;
