import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import base from '../../../Config/config';
import { getUserInfo } from "../../../store/actions/userInfoAction";
import { useParams } from "react-router-dom";
import "./athleteprofile.scss";
import axios from "axios";
import Navbar from "../../../Components/Navbar/Navbar";
import Sidebar from "../../../Components/Sidebar/sidebar";
import Footer from "../../../Components/Footer/Footer";
import AthleteAddProfileBanner from "../../../Components/PageComponents/AthleteAddProfileBanner/AthleteAddProfileBanner";
import videoicon from "../../../Assets/Images/video-icon.svg";
import imageicon from "../../../Assets/Images/photo-icon.svg";
import linkicon from "../../../Assets/Images/link-icon.svg";
import eventicon from "../../../Assets/Images/event-icon.svg";
import loveicon from "../../../Assets/Images/love-icon.svg";
import staricon from "../../../Assets/Images/star-icon.svg";
import fireicon from "../../../Assets/Images/fire-icon.svg";
import contacticon from "../../../Assets/Images/contact-icon.svg";

import { Modal, CircularProgress } from "@mui/material";

const AthleteProfile = () => {
  const { profileId } = useParams();
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);

  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [linkModalOpen, setLinkModalOpen] = useState(false);
  const [videoModalOpen, setVideoModalOpen] = useState(false);
  const [eventsModalOpen, setEventsModalOpen] = useState(false);
  // const [accoladesModalOpen, setAccoladesModalOpen] = useState(false);
  // const [offersModalOpen, setOffersModalOpen] = useState(false);
  const [contactModalOpen, setContactModalOpen] = useState(false);

  const [editIndex, setEditIndex] = useState(null);
  const [editVideoIndex, setEditVideoIndex] = useState(null);

  const dispatch = useDispatch();
  const { user, error } = useSelector((state) => state.userInfo);

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getUserInfo());
      setLoading(false);
    };

    fetchData();
  }, [dispatch]);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const token = localStorage.getItem("token");
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        };

        const response = await axios.get(
          `${base.BASE_URL}/sportProfile/getById/${profileId}`,
          config
        );

        setProfile(response.data.user);
        console.log(response.data.user);
        setEvents(response.data.user.data.events);
        setReferences(response.data.user.data.references);
        setAccolades(response.data.user.data.accolades);
        setOffers(response.data.user.data.offers);
        setContactData(response.data.user.data.contactInformation);
        setLinks(response.data.user.data.links);
        setImageLink(response.data.user.data.photos);
        setVideos(response.data.user.data.videos);
      } catch (error) {
        console.error("Error fetching profile data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProfile();
  }, [profileId]);

  // States for form data
  const [selectedImage, setSelectedImage] = useState(null);
  const [linkData, setLinkData] = useState({
    title: "",
    url: "",
  });

  const [links, setLinks] = useState([]);

  const handleLinkChange = (e) => {
    const { name, value } = e.target;
    setLinkData({
      ...linkData,
      [name]: value,
    });
  };

  const handleLinkUpload = (e) => {
    e.preventDefault();
    setLinks([...links, linkData]);
    setLinkData({
      title: "",
      url: "",
    });
  };

  const [videoUrl, setVideoUrl] = useState("");
  const [videos, setVideos] = useState([]);

  const handleVideoChange = (e) => {
    const { value } = e.target;
    setVideoUrl(value);
  };

  const handleVideoUpload = (e) => {
    e.preventDefault();
    if (editVideoIndex !== null) {
      // Update existing video
      const updatedVideos = videos.map((url, index) =>
        index === editVideoIndex ? videoUrl : url
      );
      setVideos(updatedVideos);
      setEditVideoIndex(null);
    } else {
      // Add new video
      setVideos([...videos, videoUrl]);
    }
    setVideoUrl("");
    setVideoModalOpen(false);
  };

  const handleEditVideo = (index) => {
    setVideoUrl(videos[index]);
    setEditVideoIndex(index);
    setVideoModalOpen(true);
  };

  const handleDeleteVideo = (index) => {
    const filteredVideos = videos.filter((_, i) => i !== index);
    setVideos(filteredVideos);
  };

  const [eventData, setEventData] = useState({
    eventName: "",
    eventDate: "",
    eventLocation: "",
    eventUrl: "",
    eventInfo: "",
  });

  const [events, setEvents] = useState([]);

  const handleEventChange = (e) => {
    const { name, value } = e.target;
    setEventData({
      ...eventData,
      [name]: value,
    });
  };

  const handleEventUpload = (e) => {
    e.preventDefault();
    if (editIndex !== null) {
      // Update existing event
      const updatedEvents = events.map((event, index) =>
        index === editIndex ? eventData : event
      );
      setEvents(updatedEvents);
      setEditIndex(null);
    } else {
      // Add new event
      setEvents([...events, eventData]);
    }
    setEventData({
      eventName: "",
      eventDate: "",
      eventLocation: "",
      eventUrl: "",
      eventInfo: "",
    });
    setEventsModalOpen(false);
  };

  const handleEditEvent = (index) => {
    setEventData(events[index]);
    setEditIndex(index);
    setEventsModalOpen(true);
  };

  const handleDeleteEvent = (index) => {
    const filteredEvents = events.filter((_, i) => i !== index);
    setEvents(filteredEvents);
  };

  const [referenceData, setReferenceData] = useState({
    referenceName: "",
    referenceEmail: "",
    referenceTestimonial: "",
    referencePhoneNumber: "",
    referenceRelationship: "",
  });
  const [references, setReferences] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null); // For tracking the index of the reference being edited
  const [referenceModalOpen, setReferenceModalOpen] = useState(false);
  const [editReferenceModalOpen, setEditReferenceModalOpen] = useState(false); // Modal for editing references

  const handleReferenceChange = (e) => {
    const { name, value } = e.target;
    setReferenceData({
      ...referenceData,
      [name]: value,
    });
  };

  const handleReferenceUpload = (e) => {
    e.preventDefault();
    if (editIndex !== null) {
      // Update existing reference
      const updatedReferences = references.map((reference, index) =>
        index === editIndex ? referenceData : reference
      );
      setReferences(updatedReferences);
      setEditIndex(null);
    } else {
      // Add new reference
      setReferences([...references, referenceData]);
    }
    setReferenceData({
      referenceName: "",
      referenceEmail: "",
      referenceTestimonial: "",
      referencePhoneNumber: "",
      referenceRelationship: "",
    });
    setReferenceModalOpen(false);
  };

  const handleEditReference = (index) => {
    setReferenceData(references[index]);
    setEditIndex(index);
    setReferenceModalOpen(true);
  };

  const handleDeleteReference = (index) => {
    const filteredReferences = references.filter((_, i) => i !== index);
    setReferences(filteredReferences);
  };

  const [accoladesModalOpen, setAccoladesModalOpen] = useState(false);
  const [accoladeData, setAccoladeData] = useState({
    accoladeName: "",
    yearAchieved: "",
    accoladeDescription: "",
  });
  const [accolades, setAccolades] = useState([]);
  const [editAccoladeIndex, setEditAccoladeIndex] = useState(null);

  const handleAccoladeChange = (e) => {
    const { name, value } = e.target;
    setAccoladeData({
      ...accoladeData,
      [name]: value,
    });
  };

  const handleAccoladeUpload = (e) => {
    e.preventDefault();
    if (editAccoladeIndex !== null) {
      const updatedAccolades = accolades.map((accolade, index) =>
        index === editAccoladeIndex ? accoladeData : accolade
      );
      setAccolades(updatedAccolades);
      setEditAccoladeIndex(null);
    } else {
      setAccolades([...accolades, accoladeData]);
    }
    setAccoladeData({
      accoladeName: "",
      yearAchieved: "",
      accoladeDescription: "",
    });
    setAccoladesModalOpen(false);
  };

  const handleEditAccolade = (index) => {
    setAccoladeData(accolades[index]);
    setEditAccoladeIndex(index);
    setAccoladesModalOpen(true);
  };

  const handleDeleteAccolade = (index) => {
    const filteredAccolades = accolades.filter((_, i) => i !== index);
    setAccolades(filteredAccolades);
  };

  const [offersModalOpen, setOffersModalOpen] = useState(false);
  const [offerData, setOfferData] = useState({
    college: "",
    status: "",
  });
  const [offers, setOffers] = useState([]);
  const [editOfferIndex, setEditOfferIndex] = useState(null);

  const handleOfferChange = (e) => {
    const { name, value } = e.target;
    setOfferData({
      ...offerData,
      [name]: value,
    });
  };

  const handleOfferUpload = (e) => {
    e.preventDefault();
    if (editOfferIndex !== null) {
      // Update existing offer
      const updatedOffers = offers.map((offer, index) =>
        index === editOfferIndex ? offerData : offer
      );
      setOffers(updatedOffers);
      setEditOfferIndex(null);
    } else {
      // Add new offer
      setOffers([...offers, offerData]);
    }
    setOfferData({
      college: "",
      status: "",
    });
    setOffersModalOpen(false);
  };

  const handleEditOffer = (index) => {
    setOfferData(offers[index]);
    setEditOfferIndex(index);
    setOffersModalOpen(true);
  };

  const handleDeleteOffer = (index) => {
    const filteredOffers = offers.filter((_, i) => i !== index);
    setOffers(filteredOffers);
  };

  const [contactData, setContactData] = useState({
    email: "",
    phoneNumber: "",
    instagramHandle: "",
    twitterHandle: "",
    coachName: "",
    coachEmail: "",
    coachPhoneNumber: "",
    parentOneName: "",
    parentOneEmail: "",
    parentOnePhoneNumber: "",
    parentTwoName: "",
    parentTwoEmail: "",
    parentTwoPhoneNumber: "",
    recruitingCoordinatorName: "",
    recruitingCoordinatorEmail: "",
    recruitingCoordinatorPhoneNumber: "",
  });

  const handleContactChange = (e) => {
    const { name, value } = e.target;
    setContactData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleContactUpload = (e) => {
    e.preventDefault();
    console.log("Contact Data:", contactData);
    // Reset the form after submission
    setContactData(contactData);
    setContactModalOpen(false);
  };

  const handleDeleteContact = () => {
    // Implement deletion logic here
    // Prompt user for confirmation, then delete if confirmed
    const confirmDelete = window.confirm(
      "Are you sure you want to delete the contact information?"
    );
    if (confirmDelete) {
      setContactData({
        email: "",
        phoneNumber: "",
        instagramHandle: "",
        twitterHandle: "",
        coachName: "",
        coachEmail: "",
        coachPhoneNumber: "",
        parentOneName: "",
        parentOneEmail: "",
        parentOnePhoneNumber: "",
        parentTwoName: "",
        parentTwoEmail: "",
        parentTwoPhoneNumber: "",
        recruitingCoordinatorName: "",
        recruitingCoordinatorEmail: "",
        recruitingCoordinatorPhoneNumber: "",
      });
    }
  };

  const handleInfoUpload = async (event) => {
    event.preventDefault();
    setLoading(true);
    console.log(videos);
    try {
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const data = {
        sportProfileId: profileId,
        events: events,
        accolades: accolades,
        offers: offers,
        references: references,
        links: links,
        contactInformation: contactData,
        photos: imageLink,
        videos: videos,
      };
      await axios.put(
        `${base.BASE_URL}/sportProfile/update`,
        data,
        config
      );
      console.log("api hit successfully");
    } catch (error) {
      console.error("Error sending data:", error);
    } finally {
      setLoading(false);
    }
    // setLoading(false)
  };

  const [imageLink, setImageLink] = useState([]);

  const handleImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setSelectedImage(event.target.files[0]);
    }
  };

  const handleImageUpload = async (event) => {
    event.preventDefault();
    setLoading(true);

    const formData = new FormData();
    formData.append("file", selectedImage);

    try {
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };

      const response = await axios.post(
        "https://four-coaches-9a9d64692f37.herokuapp.com/upload",
        formData,
        config
      );
      setImageLink((prevLinks) => [...prevLinks, response.data.link]);
      setImageModalOpen(false);
    } catch (error) {
      console.error("Error uploading image:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteImage = (index) => {
    const filteredImages = imageLink.filter((_, i) => i !== index);
    setImageLink(filteredImages);
  };

  // console.log("link image", imageLink);

  // const handleLinkChange = (e) => {
  //   const { name, value } = e.target;
  //   setLinkData({ ...linkData, [name]: value });
  // };

  // const handleLinkUpload = async (event) => {
  //   event.preventDefault();
  //   try {
  //     const token = localStorage.getItem("token");
  //     const config = {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //         "Content-Type": "multipart/form-data",
  //       },
  //     };

  //     const data = {
  //       title: linkData.title,
  //       url: linkData.url,
  //     };
  //     await axios.put("https://your-api-endpoint/uploadImage", config);
  //     setImageModalOpen(false);
  //   } catch (error) {
  //     console.error("Error uploading image:", error);
  //   } finally {
  //     setLoading(false);
  //   }
  //   setLinkModalOpen(false);
  // };

  // const handleEventChange = (e) => {
  //   const { name, value } = e.target;
  //   setEventData({ ...eventData, [name]: value });
  // };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const isYouTubeUrl = (url) => {
    return url.includes("youtube.com") || url.includes("youtu.be");
  };

  // Function to check if the URL belongs to Vimeo
  const isVimeoUrl = (url) => {
    return url.includes("vimeo.com");
  };

  // Function to check if the URL belongs to any other platform
  const isOtherPlatformUrl = (url) => {
    return !isYouTubeUrl(url) && !isVimeoUrl(url);
  };

  if (loading) {
    return (
      <div
        className="loader-container d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <CircularProgress />
      </div>
    );
  } else {
    return (
      <>
        <div className="dashpage-wrapper">
          <div className="container-fluid">
            <Navbar />
            <div className="row">
              <div className="col-lg-2">
                <Sidebar />
              </div>
              <div className="col-lg-10">
                <>
                  <AthleteAddProfileBanner />

                  <section className="profile-info" id="profile">
                    <div className="profile-info-head">
                      <p>My Profile</p>
                    </div>

                    <div className="info-wrapper">
                      <div className="info-wrapper-head">
                        <div className="heading">
                          <i className="fa fa-user" aria-hidden="true"></i>
                          <p>Profile Information</p>
                        </div>
                        {/* <div className="edit-btn-wrapper">Edit Information</div> */}
                      </div>
                      <div className="info-wrapper-content">
                        <div className="row">
                          <div className="col-lg-3">
                            <div className="info-detail">
                              <h4>Your Name</h4>
                              <p>{user?.username}</p>
                            </div>
                            <div className="info-detail">
                              <h4>School</h4>
                              <p>{user?.profile?.school}</p>
                            </div>
                            <div className="info-detail">
                              <h4>Location</h4>
                              <p>{user?.profile?.location}</p>
                            </div>
                          </div>
                          <div className="col-lg-3">
                            <div className="info-detail">
                              <h4>GPA</h4>
                              <p>{user?.profile?.gpa}</p>
                            </div>
                            <div className="info-detail">
                              <h4>Sat Score</h4>
                              <p>{user?.profile?.satScore}</p>
                            </div>
                            <div className="info-detail">
                              <h4>Act Score</h4>
                              <p>{user?.profile?.actScore}</p>
                            </div>
                          </div>
                          <div className="col-lg-3">
                            <div className="info-detail">
                              <h4>Intended Major</h4>
                              <p>{user?.profile?.intendedMajor}</p>
                            </div>
                            <div className="info-detail">
                              <h4>Birth Day</h4>
                              <p>
                                {user?.profile &&
                                  formatDate(user.profile.birthDate)}
                              </p>
                            </div>
                          </div>
                          <div className="col-lg-3"></div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section className="profile-info mt-3" id="videos">
                    <div className="info-wrapper">
                      <div className="info-wrapper-head">
                        <div className="heading">
                          <img src={videoicon} alt="" />
                          <p>Videos</p>
                        </div>
                        <div className="edit-btn-wrapper">
                          <button onClick={() => setVideoModalOpen(true)}>
                            Add Video
                          </button>
                        </div>
                      </div>
                      <div className="info-wrapper-content">
                        <div className="row">
                          {videos.map((url, index) => (
                            <div className="col-lg-4" key={index}>
                              <div className="video-wrapper">
                                {isYouTubeUrl(url) && (
                                  <iframe
                                    width="320"
                                    height="240"
                                    src={url}
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                    title="YouTube video"
                                  ></iframe>
                                )}
                                {isVimeoUrl(url) && (
                                  <iframe
                                    width="320"
                                    height="240"
                                    src={url}
                                    frameBorder="0"
                                    allow="autoplay; fullscreen"
                                    allowFullScreen
                                    title="Vimeo video"
                                  ></iframe>
                                )}
                                {isOtherPlatformUrl(url) && (
                                  <video controls>
                                    <source src={url} type="video/mp4" />
                                    Your browser does not support the video tag.
                                  </video>
                                )}
                                <div className="actions-btn">
                                  <button
                                    onClick={() => handleEditVideo(index)}
                                    className="edit-btn"
                                  >
                                    <i
                                      className="fa fa-pencil-square-o"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                  <button
                                    onClick={() => handleDeleteVideo(index)}
                                    className="dlt-btn"
                                  >
                                    <i
                                      className="fa fa-trash-o"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </section>

                  <section className="profile-info mt-3" id="images">
                    <div className="info-wrapper">
                      <div className="info-wrapper-head">
                        <div className="heading">
                          <img src={imageicon} alt="" />
                          <p>Images</p>
                        </div>
                        <div className="edit-btn-wrapper">
                          <button onClick={() => setImageModalOpen(true)}>
                            Add Images
                          </button>
                        </div>
                      </div>
                      <div className="info-wrapper-content">
                        <div className="row">
                          {imageLink
                            .filter((img) => img !== null)
                            .map((img, index) => (
                              <div className="col-lg-4" key={index}>
                                <div className="image-wrapper">
                                  <img src={img} alt="" />
                                  <div className="actions-btn">
                                    <button
                                      onClick={() => handleDeleteImage(index)}
                                      className="dlt-btn"
                                    >
                                      <i
                                        className="fa fa-trash-o"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  </section>

                  <section className="profile-info mt-3" id="links">
                    <div className="info-wrapper">
                      <div className="info-wrapper-head">
                        <div className="heading">
                          <img src={linkicon} alt="" />
                          <p>Links</p>
                        </div>
                        <div className="edit-btn-wrapper">
                          <button onClick={() => setLinkModalOpen(true)}>
                            Edit Information
                          </button>
                        </div>
                      </div>
                      <div className="info-wrapper-content">
                        <div className="links-cards-wrapper">
                          {links.map((link, index) => (
                            <div className="link-card" key={index}>
                              <h4>{link.title}</h4>
                              <p>
                                <a href={link.url}>{link.url}</a>
                              </p>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </section>

                  {/* <section className="profile-info mt-3" id="events">
                    <div className="info-wrapper">
                      <div className="info-wrapper-head">
                        <div className="heading">
                          <img src={eventicon} alt="" />
                          <p>Events</p>
                        </div>
                        <div className="edit-btn-wrapper">
                          <button onClick={() => setEventsModalOpen(true)}>
                            Edit Information
                          </button>
                        </div>
                      </div>
                      <div className="info-wrapper-content">
                        <div className="events-list">
                          <div className="events-cards-wrapper">
                            {events.map((event, index) => (
                              <div className="event-card" key={index}>
                                <h4>{event.eventName}</h4>
                                <p>
                                  <a href={event.eventUrl}>{event.url}</a>
                                </p>
                                <p>{event.eventLocation}</p>
                                <p>{formatDate(event.eventDate)}</p>
                                <div className="event-description">
                                  <p>{event.eventInfo}</p>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </section> */}

                  <section className="profile-info mt-3" id="events">
                    <div className="info-wrapper">
                      <div className="info-wrapper-head">
                        <div className="heading">
                          <img src={eventicon} alt="" />
                          <p>Events</p>
                        </div>
                        <div className="edit-btn-wrapper">
                          <button onClick={() => setEventsModalOpen(true)}>
                            Add Event
                          </button>
                        </div>
                      </div>
                      <div className="info-wrapper-content">
                        <div className="events-list">
                          <div className="events-cards-wrapper">
                            {events.map((event, index) => (
                              <div className="event-card" key={index}>
                                <h4>{event.eventName}</h4>
                                <p>
                                  <a href={event.eventUrl}>{event.url}</a>
                                </p>
                                <p>{event.eventLocation}</p>
                                <p>{formatDate(event.eventDate)}</p>
                                <div className="event-description">
                                  <p>{event.eventInfo}</p>
                                </div>
                                <div className="actions-btn">
                                  <button
                                    onClick={() => handleEditEvent(index)}
                                    className="edit-btn"
                                  >
                                    <i
                                      className="fa fa-pencil-square-o"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                  <button
                                    onClick={() => handleDeleteEvent(index)}
                                    className="dlt-btn"
                                  >
                                    <i
                                      className="fa fa-trash-o"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>

                  <section className="profile-info mt-3" id="references">
                    <div className="info-wrapper">
                      <div className="info-wrapper-head">
                        <div className="heading">
                          <img src={loveicon} alt="" />
                          <p>References</p>
                        </div>
                        <div className="edit-btn-wrapper">
                          <button onClick={() => setReferenceModalOpen(true)}>
                            Add Reference
                          </button>
                        </div>
                      </div>
                      <div className="info-wrapper-content">
                        <div className="references-wrapper">
                          {references.map((reference, index) => (
                            <div className="reference" key={index}>
                              <div className="reference-detail">
                                <h4>Reference Name</h4>
                                <p>{reference.referenceName}</p>
                              </div>
                              <div className="reference-detail">
                                <h4>Reference Email</h4>
                                <p className="email">
                                  {reference.referenceEmail}
                                </p>
                              </div>
                              <div className="reference-detail">
                                <h4>Reference Phone</h4>
                                <p>{reference.referencePhoneNumber}</p>
                              </div>
                              <div className="reference-detail">
                                <h4>Reference Relationship</h4>
                                <p className="relationship">
                                  {reference.referenceRelationship}
                                </p>
                              </div>
                              <div className="reference-detail">
                                <h4>Reference Testimonial</h4>
                                <p>{reference.referenceTestimonial}</p>
                              </div>
                              <div className="actions-btn">
                                <button
                                  onClick={() => handleEditReference(index)}
                                  className="edit-btn"
                                >
                                  <i
                                    className="fa fa-pencil-square-o"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                                <button
                                  onClick={() => handleDeleteReference(index)}
                                  className="dlt-btn"
                                >
                                  <i
                                    className="fa fa-trash-o"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </section>

                  <section className="profile-info mt-3" id="accolades">
                    <div className="info-wrapper">
                      <div className="info-wrapper-head">
                        <div className="heading">
                          <img src={staricon} alt="" />
                          <p>Accolades</p>
                        </div>
                        <div className="edit-btn-wrapper">
                          <button onClick={() => setAccoladesModalOpen(true)}>
                            Add Accolade
                          </button>
                        </div>
                      </div>
                      <div className="info-wrapper-content">
                        <div className="accolades-wrapper">
                          <div className="accolades row">
                            {accolades.map((accolade, index) => (
                              <div className="col-lg-3">
                                <div className="accolade " key={index}>
                                  <div className="accolade-head">
                                    <h4>{accolade.accoladeName}</h4>
                                    <p>{accolade.yearAchieved}</p>
                                  </div>
                                  <div className="accolade-description">
                                    <p>{accolade.accoladeDescription}</p>
                                  </div>
                                  <div className="actions-btn">
                                    <button
                                      onClick={() => handleEditAccolade(index)}
                                      className="edit-btn"
                                    >
                                      <i
                                        className="fa fa-pencil-square-o"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                    <button
                                      onClick={() =>
                                        handleDeleteAccolade(index)
                                      }
                                      className="dlt-btn"
                                    >
                                      <i
                                        className="fa fa-trash-o"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>

                  <section className="profile-info mt-3" id="offers">
                    <div className="info-wrapper">
                      <div className="info-wrapper-head">
                        <div className="heading">
                          <img src={fireicon} alt="" />
                          <p>Offers</p>
                        </div>
                        <div className="edit-btn-wrapper">
                          <button onClick={() => setOffersModalOpen(true)}>
                            <i
                              className="fa fa-pencil-square-o"
                              aria-hidden="true"
                            ></i>
                          </button>
                        </div>
                      </div>
                      <div className="info-wrapper-content">
                        <div className="offers-cards-wrapper">
                          {offers.map((offer, index) => (
                            <div className="offer-card" key={index}>
                              <h4>College: {offer.college}</h4>
                              <p>Status: {offer.status}</p>
                              <div className="actions-btn">
                                <button
                                  onClick={() => handleEditOffer(index)}
                                  className="edit-btn"
                                >
                                  <i
                                    className="fa fa-pencil-square-o"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                                <button
                                  onClick={() => handleDeleteOffer(index)}
                                  className="dlt-btn"
                                >
                                  <i
                                    className="fa fa-trash-o"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </section>

                  <section className="profile-info mt-3" id="contact">
                    <div className="info-wrapper">
                      <div className="info-wrapper-head">
                        <div className="heading">
                          <img src={contacticon} alt="" />
                          <p>Contact</p>
                        </div>
                        <div className="edit-btn-wrapper">
                          <button onClick={() => setContactModalOpen(true)}>
                            Edit Information
                          </button>
                        </div>
                      </div>
                      <div className="info-wrapper-content">
                        <div className="contact-info-wrapper">
                          <div className="contacts-list">
                            <div className="contact-info">
                              {contactData.email ? (
                                <>
                                  <p>
                                    Email: <span>{contactData.email}</span>{" "}
                                  </p>
                                  <p>
                                    Phone:{" "}
                                    <span>{contactData.phoneNumber}</span>
                                  </p>
                                  <p>
                                    Twitter Handle:{" "}
                                    <span>{contactData.twitterHandle}</span>
                                  </p>
                                  <p>
                                    Instagram Handle:{" "}
                                    <span>{contactData.instagramHandle}</span>
                                  </p>
                                  <p>
                                    Coach Name:{" "}
                                    <span>{contactData.coachName}</span>
                                  </p>
                                  <p>
                                    Coach Email:{" "}
                                    <span>{contactData.coachEmail}</span>{" "}
                                  </p>
                                  <p>
                                    Coach Phone:{" "}
                                    <span> {contactData.coachPhoneNumber}</span>{" "}
                                  </p>
                                  <p>
                                    Parent 1 Name:{" "}
                                    <span>{contactData.parentOneName}</span>{" "}
                                  </p>
                                  <p>
                                    Parent 1 Email:{" "}
                                    <span> {contactData.parentOneEmail}</span>{" "}
                                  </p>
                                  <p>
                                    Parent 1 Phone:{" "}
                                    <span>
                                      {" "}
                                      {contactData.parentOnePhoneNumber}
                                    </span>{" "}
                                  </p>
                                  <p>
                                    Recruiting Coordinator Name:{" "}
                                    <span>
                                      {contactData.recruitingCoordinatorName}
                                    </span>
                                  </p>
                                  <p>
                                    Recruiting Coordinator Email:{" "}
                                    <span>
                                      {contactData.recruitingCoordinatorEmail}
                                    </span>
                                  </p>
                                  <p>
                                    Recruiting Coordinator Phone:{" "}
                                    <span>
                                      {
                                        contactData.recruitingCoordinatorPhoneNumber
                                      }
                                    </span>
                                  </p>
                                  <p>
                                    Parent 2 Name:
                                    <span>{contactData.parentTwoName}</span>
                                  </p>
                                  <p>
                                    Parent 2 Email:
                                    <span>{contactData.parentTwoEmail}</span>
                                  </p>
                                  <p>
                                    Parent 2 Phone:{" "}
                                    <span>
                                      {contactData.parentTwoPhoneNumber}
                                    </span>
                                  </p>
                                  <div className="actions-btn">
                                    <button
                                      onClick={handleDeleteContact}
                                      className="dlt-btn"
                                    >
                                      <i
                                        className="fa fa-trash-o"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                  </div>
                                </>
                              ) : (
                                <p>No Contact Information Available</p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>

                  <div className="all-info-save-btn">
                    <button onClick={handleInfoUpload}>
                      Save All Information
                    </button>
                  </div>
                </>
              </div>
            </div>
          </div>
        </div>
        <Footer />
        <Modal
          open={videoModalOpen}
          onClose={() => {
            setVideoModalOpen(false);
            setEditVideoIndex(null);
            setVideoUrl("");
          }}
          className="modal-wrapper"
          id="video-modal"
        >
          <div className="modal-content">
            <div className="modal-head">
              <h2 id="modal-modal-title" className="modal-title">
                {editVideoIndex !== null ? "Edit Video" : "Upload Video"}
              </h2>
            </div>
            <form onSubmit={handleVideoUpload}>
              <div className="form-group">
                <label htmlFor="linktitle2">URL</label>
                <input
                  type="url"
                  name="url"
                  placeholder="Video URL"
                  value={videoUrl}
                  onChange={handleVideoChange}
                />
              </div>
              <button type="submit" className="modal-submit-btn">
                {editVideoIndex !== null ? "Update Video" : "Add Video"}
              </button>
            </form>
          </div>
        </Modal>

        <Modal
          open={imageModalOpen}
          onClose={() => setImageModalOpen(false)}
          className="modal-wrapper"
          id="image-modal"
        >
          <div className="modal-content">
            <div className="modal-head">
              <h2 id="modal-modal-title" className="modal-title">
                Upload Photos
              </h2>
            </div>
            <form onSubmit={handleImageUpload}>
              <div className="form-group">
                <label htmlFor="imageUpload">Select Image</label>
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                />
              </div>
              <button
                type="submit"
                className="modal-submit-btn"
                disabled={loading}
              >
                {loading ? "Uploading..." : "Upload"}
              </button>
            </form>
          </div>
        </Modal>

        <Modal
          open={linkModalOpen}
          onClose={() => setLinkModalOpen(false)}
          className="modal-wrapper"
          id="links-modal"
        >
          <div className="modal-content">
            <div className="modal-head">
              <h2 id="modal-modal-title" className="modal-title">
                Upload Link
              </h2>
            </div>
            <form onSubmit={handleLinkUpload}>
              <div className="form-group">
                <label htmlFor="linktitle1">Title</label>
                <input
                  type="text"
                  name="title"
                  placeholder="Link Title"
                  value={linkData.title}
                  onChange={handleLinkChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="linktitle2">Url</label>
                <input
                  type="url"
                  name="url"
                  placeholder="Link URL"
                  value={linkData.url}
                  onChange={handleLinkChange}
                />
              </div>
              <button type="submit" className="modal-submit-btn">
                Add Link
              </button>
            </form>
          </div>
        </Modal>

        <Modal
          open={eventsModalOpen}
          onClose={() => {
            setEventsModalOpen(false);
            setEditIndex(null);
            setEventData({
              eventName: "",
              eventDate: "",
              eventLocation: "",
              eventUrl: "",
              eventInfo: "",
            });
          }}
          className="modal-wrapper"
          id="events-modal"
        >
          <div className="modal-content">
            <div className="modal-head">
              <h2 id="modal-modal-title" className="modal-title">
                {editIndex !== null ? "Edit Event" : "Add Event"}
              </h2>
            </div>
            <form onSubmit={handleEventUpload}>
              <div className="form-group">
                <label htmlFor="eventName">Event Name</label>
                <input
                  type="text"
                  name="eventName"
                  placeholder="Event Name"
                  value={eventData.eventName}
                  onChange={handleEventChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="eventDate">Event Date</label>
                <input
                  type="date"
                  name="eventDate"
                  value={eventData.eventDate}
                  onChange={handleEventChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="eventLocation">Event Location</label>
                <input
                  type="text"
                  name="eventLocation"
                  placeholder="Event Location"
                  value={eventData.eventLocation}
                  onChange={handleEventChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="eventurl">Event Url</label>
                <input
                  type="url"
                  name="eventUrl"
                  placeholder="Event URL"
                  value={eventData.eventUrl}
                  onChange={handleEventChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="eventInfo">Event Info</label>
                <textarea
                  name="eventInfo"
                  placeholder="Event Information"
                  value={eventData.eventInfo}
                  onChange={handleEventChange}
                />
              </div>
              <button type="submit" className="modal-submit-btn">
                {editIndex !== null ? "Update Event" : "Upload Event"}
              </button>
            </form>
          </div>
        </Modal>

        <Modal
          open={referenceModalOpen}
          onClose={() => {
            setReferenceModalOpen(false);
            setEditIndex(null);
            setReferenceData({
              referenceName: "",
              referenceEmail: "",
              referenceTestimonial: "",
              referencePhoneNumber: "",
              referenceRelationship: "",
            });
          }}
          className="modal-wrapper"
          id="reference-modal"
        >
          <div className="modal-content">
            <div className="modal-head">
              <h2 id="modal-modal-title" className="modal-title">
                {editIndex !== null ? "Edit Reference" : "Add Reference"}
              </h2>
            </div>
            <form onSubmit={handleReferenceUpload}>
              <div className="form-group">
                <label htmlFor="referenceName">Reference Name</label>
                <input
                  type="text"
                  name="referenceName"
                  placeholder="Reference Name"
                  value={referenceData.referenceName}
                  onChange={handleReferenceChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="referenceEmail">Reference Email</label>
                <input
                  type="email"
                  name="referenceEmail"
                  placeholder="Reference Email"
                  value={referenceData.referenceEmail}
                  onChange={handleReferenceChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="referenceTestimonial">
                  Reference Testimonial
                </label>
                <textarea
                  name="referenceTestimonial"
                  placeholder="Testimonials"
                  value={referenceData.referenceTestimonial}
                  onChange={handleReferenceChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="referencePhoneNumber">Reference Phone</label>
                <input
                  type="tel"
                  name="referencePhoneNumber"
                  placeholder="Reference Phone"
                  value={referenceData.referencePhoneNumber}
                  onChange={handleReferenceChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="referenceRelationship">
                  Reference Relationship
                </label>
                <input
                  type="text"
                  name="referenceRelationship"
                  placeholder="Relationship"
                  value={referenceData.referenceRelationship}
                  onChange={handleReferenceChange}
                />
              </div>
              <button type="submit" className="modal-submit-btn">
                {editIndex !== null ? "Update Reference" : "Upload Reference"}
              </button>
            </form>
          </div>
        </Modal>

        <Modal
          open={accoladesModalOpen}
          onClose={() => {
            setAccoladesModalOpen(false);
            setEditAccoladeIndex(null);
            setAccoladeData({
              accoladeName: "",
              yearAchieved: "",
              accoladeDescription: "",
            });
          }}
          className="modal-wrapper"
          id="accolades-modal"
        >
          <div className="modal-content">
            <div className="modal-head">
              <h2 id="modal-modal-title" className="modal-title">
                {editAccoladeIndex !== null ? "Edit Accolade" : "Add Accolade"}
              </h2>
            </div>
            <form onSubmit={handleAccoladeUpload}>
              <div className="form-group">
                <label htmlFor="accoladeName">Accolade Name</label>
                <input
                  type="text"
                  name="accoladeName"
                  placeholder="Accolade Name"
                  value={accoladeData.accoladeName}
                  onChange={handleAccoladeChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="yearAchieved">Year Achieved</label>
                <input
                  type="text"
                  name="yearAchieved"
                  placeholder="Year"
                  value={accoladeData.yearAchieved}
                  onChange={handleAccoladeChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="accoladeDescription">
                  Accolade Description
                </label>
                <textarea
                  name="accoladeDescription"
                  placeholder="Description"
                  value={accoladeData.accoladeDescription}
                  onChange={handleAccoladeChange}
                />
              </div>
              <button type="submit" className="modal-submit-btn">
                {editAccoladeIndex !== null
                  ? "Update Accolade"
                  : "Upload Accolade"}
              </button>
            </form>
          </div>
        </Modal>

        <Modal
          open={offersModalOpen}
          onClose={() => {
            setOffersModalOpen(false);
            setEditOfferIndex(null);
            setOfferData({
              college: "",
              status: "",
            });
          }}
          className="modal-wrapper"
          id="offers-modal"
        >
          <div className="modal-content">
            <div className="modal-head">
              <h2 id="modal-modal-title" className="modal-title">
                {editOfferIndex !== null ? "Edit Offer" : "Add Offer"}
              </h2>
            </div>
            <form onSubmit={handleOfferUpload}>
              <div className="form-group">
                <label htmlFor="college">College</label>
                <input
                  type="text"
                  name="college"
                  placeholder="College"
                  value={offerData.college}
                  onChange={handleOfferChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="status">Status</label>
                <input
                  type="text"
                  name="status"
                  placeholder="Status"
                  value={offerData.status}
                  onChange={handleOfferChange}
                />
              </div>
              <button type="submit" className="modal-submit-btn">
                {editOfferIndex !== null ? "Update Offer" : "Upload Offer"}
              </button>
            </form>
          </div>
        </Modal>

        <Modal
          open={contactModalOpen}
          onClose={() => setContactModalOpen(false)}
          className="modal-wrapper"
          id="contact-modal"
        >
          <div className="modal-content">
            <div className="modal-head">
              <h2 id="modal-modal-title" className="modal-title">
                Add Contact
              </h2>
            </div>
            <form onSubmit={handleContactUpload}>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  name="email"
                  placeholder="Contact Email"
                  value={contactData.email}
                  onChange={handleContactChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="phoneNumber">Phone Number</label>
                <input
                  type="tel"
                  name="phoneNumber"
                  placeholder="Phone Number"
                  value={contactData.phoneNumber}
                  onChange={handleContactChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="twitterHandle">Twitter Handle</label>
                <input
                  type="text"
                  name="twitterHandle"
                  placeholder="Twitter Handle"
                  value={contactData.twitterHandle}
                  onChange={handleContactChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="instagramHandle">Instagram Handle</label>
                <input
                  type="text"
                  name="instagramHandle"
                  placeholder="Instagram Handle"
                  value={contactData.instagramHandle}
                  onChange={handleContactChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="coachName">Coach Name</label>
                <input
                  type="text"
                  name="coachName"
                  placeholder="Coach Name"
                  value={contactData.coachName}
                  onChange={handleContactChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="coachEmail">Coach Email</label>
                <input
                  type="email"
                  name="coachEmail"
                  placeholder="Coach Email"
                  value={contactData.coachEmail}
                  onChange={handleContactChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="coachPhoneNumber">Coach Phone</label>
                <input
                  type="text"
                  name="coachPhoneNumber"
                  placeholder="Coach Phone"
                  value={contactData.coachPhoneNumber}
                  onChange={handleContactChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="parentOneName">Parent 1 Name</label>
                <input
                  type="text"
                  name="parentOneName"
                  placeholder="Parent 1 Name"
                  value={contactData.parentOneName}
                  onChange={handleContactChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="parentOneEmail">Parent 1 Email</label>
                <input
                  type="email"
                  name="parentOneEmail"
                  placeholder="Parent 1 Email"
                  value={contactData.parentOneEmail}
                  onChange={handleContactChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="parentOnePhoneNumber">Parent 1 Phone</label>
                <input
                  type="text"
                  name="parentOnePhoneNumber"
                  placeholder="Parent 1 Phone"
                  value={contactData.parentOnePhoneNumber}
                  onChange={handleContactChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="recruitingCoordinatorName">
                  Recruiting Coordinator Name
                </label>
                <input
                  type="text"
                  name="recruitingCoordinatorName"
                  placeholder="Recruiting Coordinator Name"
                  value={contactData.recruitingCoordinatorName}
                  onChange={handleContactChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="recruitingCoordinatorEmail">
                  Recruiting Coordinator Email
                </label>
                <input
                  type="email"
                  name="recruitingCoordinatorEmail"
                  placeholder="Recruiting Coordinator Email"
                  value={contactData.recruitingCoordinatorEmail}
                  onChange={handleContactChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="recruitingCoordinatorPhoneNumber">
                  Recruiting Coordinator Phone
                </label>
                <input
                  type="text"
                  name="recruitingCoordinatorPhoneNumber"
                  placeholder="Recruiting Coordinator Phone"
                  value={contactData.recruitingCoordinatorPhoneNumber}
                  onChange={handleContactChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="parentTwoName">Parent 2 Name</label>
                <input
                  type="text"
                  name="parentTwoName"
                  placeholder="Parent 2 Name"
                  value={contactData.parentTwoName}
                  onChange={handleContactChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="parentTwoEmail">Parent 2 Email</label>
                <input
                  type="email"
                  name="parentTwoEmail"
                  placeholder="Parent 2 Email"
                  value={contactData.parentTwoEmail}
                  onChange={handleContactChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="parentTwoPhoneNumber">Parent 2 Phone</label>
                <input
                  type="text"
                  name="parentTwoPhoneNumber"
                  placeholder="Parent 2 Phone"
                  value={contactData.parentTwoPhoneNumber}
                  onChange={handleContactChange}
                />
              </div>
              <button type="submit" className="modal-submit-btn">
                Upload Contact
              </button>
            </form>
          </div>
        </Modal>
      </>
    );
  }
};

export default AthleteProfile;
