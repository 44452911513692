import React, { createContext, useContext, useState, useEffect } from 'react';
import base from '../Config/config';
import axios from "axios";

const ImpersonationContext = createContext();

export const ImpersonationProvider = ({ children }) => {
  const [originalToken, setOriginalToken] = useState(localStorage.getItem('originalToken'));
  const [isImpersonating, setIsImpersonating] = useState(!!localStorage.getItem('isImpersonating'));
  const [originalDetails, setOriginalDetails] = useState({
    name: localStorage.getItem('originalName'),
    role: localStorage.getItem('originalRole')
  });
  const [impersonationDetails, setImpersonationDetails] = useState({
    name: localStorage.getItem('impersonationName'),
    role: localStorage.getItem('impersonationRole')
  });

  useEffect(() => {
    if (isImpersonating) {
      setOriginalToken(localStorage.getItem('originalToken'));
      setOriginalDetails({
        name: localStorage.getItem('originalName'),
        role: localStorage.getItem('originalRole')
      });
      setImpersonationDetails({
        name: localStorage.getItem('impersonationName'),
        role: localStorage.getItem('impersonationRole')
      });
    }
  }, [isImpersonating]);

  const handleImpersonate = async (userid, name, role) => {
    try {
      const token = localStorage.getItem("token");
      const originalName = localStorage.getItem("name");
      const originalRole = localStorage.getItem("role");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const data = {
        userId: userid,
      };
      const response = await axios.post(
        `${base.BASE_URL}/admin/users/impersonateUser`,
        data,
        config
      );
      const impersonationToken = response.data.data.token;

      // Save the original token, name, and role, and set the impersonation token, name, and role
      setOriginalToken(token);
      setOriginalDetails({ name: originalName, role: originalRole });
      localStorage.setItem("originalToken", token);
      localStorage.setItem("originalName", originalName);
      localStorage.setItem("originalRole", originalRole);
      localStorage.setItem("token", impersonationToken);
      localStorage.setItem("name", name);
      localStorage.setItem("role", role);
      localStorage.setItem("isImpersonating", true);
      setIsImpersonating(true);
      setImpersonationDetails({ name, role });
      localStorage.setItem('impersonationName', name);
      localStorage.setItem('impersonationRole', role);

      console.log(
        "Impersonating with token:",
        impersonationToken,
        "name:",
        name,
        "role:",
        role
      );

      window.location.reload();
    } catch (error) {
      console.error("Impersonation error:", error);
    }
  };

  const handleStopImpersonating = () => {
    if (originalToken) {
      const { name, role } = originalDetails;
      localStorage.setItem("token", originalToken);
      localStorage.setItem("name", name);
      localStorage.setItem("role", role);
      localStorage.removeItem('originalToken');
      localStorage.removeItem('originalName');
      localStorage.removeItem('originalRole');
      localStorage.removeItem('isImpersonating');
      localStorage.removeItem('impersonationName');
      localStorage.removeItem('impersonationRole');
      setIsImpersonating(false);
      setImpersonationDetails({ name: "", role: "" });
      console.log("Stopped impersonating, reverted to original token, name, and role.");

      window.location.reload();
    }
  };

  return (
    <ImpersonationContext.Provider value={{
      isImpersonating,
      handleImpersonate,
      handleStopImpersonating,
      setOriginalToken,
      setOriginalDetails,
      setImpersonationDetails
    }}>
      {children}
    </ImpersonationContext.Provider>
  );
};

export const useImpersonation = () => {
  return useContext(ImpersonationContext);
};
