import Navigation from "./Routes/Navigation";
import './App.css';

function App() {
  return (
    <>
      <Navigation />
    </>
  );
}

export default App;
