import React, { useState } from "react";
import "./LoginForm.scss";
import LoginHeader from "../../Components/LoginHeader/LoginHeader";
import Footer from "../../Components/Footer/Footer";
import pulse from "../../Assets/Images/pulse-bg.png";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import base from "../../Config/config";

const LoginForm = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post(
        `${base.BASE_URL}/users/login/`,
        formData
      );
      if (response && response.status === 200) {
        const { token, role, username } = response.data.data;
        localStorage.setItem("token", token);
        localStorage.setItem("role", role);
        localStorage.setItem("name", username);

        window.dispatchEvent(new Event("storage"));

        toast.success("Login successful!");
        if (role === "user") {
          navigate("/athletedashboard");
        } else if (
          role === "super_administrator" ||
          role === "collegeCoach" ||
          role === "highSchoolCoach"
        ) {
          navigate("/dashboard");
        } else {
          navigate("/");
        }
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error(error.response?.data?.message || "Something went wrong!");
    } finally {
      setLoading(false);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <LoginHeader />
      <div className="forms">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 left">
              <div className="pulse-wrapper">
                <div className="pulse">
                  <img src={pulse} alt="" />
                </div>
              </div>
              <div className="form-wrapper">
                <h1>Login</h1>
                <p>
                  4 Coaches online is a platform, Where you should find multiple
                  university Against your friends all season.
                </p>
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      placeholder="Email"
                    />
                  </div>
                  <div className="form-group password-wrapper">
                    <input
                      type={showPassword ? "text" : "password"}
                      className="form-control"
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                      placeholder="Password"
                    />
                    <span className="password-toggle-icon" onClick={togglePasswordVisibility}>
                      <i className={showPassword ? "fa fa-eye-slash" : "fa fa-eye"} />
                    </span>
                  </div>
                  <div className="form-group">
                    <button
                      type="submit"
                      className="btn btn-a"
                      disabled={loading}
                    >
                      {loading ? "Loading..." : "Submit"}
                      {!loading && (
                        <i className="fa fa-arrows-h" aria-hidden="true"></i>
                      )}
                    </button>
                  </div>
                </form>
                {error && <p className="error-message">{error}</p>}
                <p>
                  <Link to="/forgotpassword">Forgot Password?</Link>
                  {"\u00A0"}
                  <Link to="/signup">Sign Up For Free</Link>
                </p>
              </div>
            </div>
            <div className="col-lg-8 right"></div>
          </div>
        </div>
      </div>
      <Footer />
      <ToastContainer />
    </>
  );
};

export default LoginForm;
