// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../Assets/Fonts/CircularStd-Medium.woff", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "Circular Std";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("woff");
}
.footer {
  background: #ddd9d9;
  padding: 20px 0;
}
.footer .footer-content-wrapper p {
  color: #3c3c3c;
  font-family: "Circular Std";
  font-size: 12px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
  margin-bottom: 0px;
}
.footer .footerlinkswrapper {
  display: flex;
  justify-content: center;
}
.footer .footerlinkswrapper .footerlinks {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 0px;
}
.footer .footerlinkswrapper .footerlinks li {
  list-style: none;
}
.footer .footerlinkswrapper .footerlinks li a {
  color: #3C3C3C;
  font-family: "Circular Std";
  font-size: 12px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
}
.footer .footerlinkswrapper .footerlinks li a:hover {
  color: #007bff;
}
.footer .footerlinkswrapper .socialLinks {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 0px;
}
.footer .footerlinkswrapper .socialLinks li {
  list-style: none;
}
.footer .footerlinkswrapper .socialLinks li a {
  text-decoration: none;
  color: #000;
  font-size: 14px;
  font-weight: 500;
}
.footer .footerlinkswrapper .socialLinks li a:hover {
  color: #007bff;
}`, "",{"version":3,"sources":["webpack://./src/Components/Footer/footer.scss"],"names":[],"mappings":"AAAA;EACE,2BAAA;EACA,2DAAA;AACF;AACA;EACE,mBAAA;EACA,eAAA;AACF;AACI;EACE,cAAA;EACA,2BAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,kBAAA;AACN;AAEE;EACE,aAAA;EACA,uBAAA;AAAJ;AACI;EACE,aAAA;EACA,SAAA;EACA,mBAAA;EACA,kBAAA;AACN;AAAM;EACE,gBAAA;AAER;AADQ;EACI,cAAA;EACA,2BAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AAGZ;AAFU;EACE,cAAA;AAIZ;AACI;EACE,aAAA;EACA,SAAA;EACA,mBAAA;EACA,kBAAA;AACN;AAAM;EACE,gBAAA;AAER;AADQ;EACE,qBAAA;EACA,WAAA;EACA,eAAA;EACA,gBAAA;AAGV;AAFU;EACE,cAAA;AAIZ","sourcesContent":["@font-face {\n  font-family: \"Circular Std\";\n  src: url(\"../../Assets/Fonts/CircularStd-Medium.woff\") format(\"woff\");\n}\n.footer {\n  background: #ddd9d9;\n  padding: 20px 0;\n  .footer-content-wrapper {\n    p {\n      color: #3c3c3c;\n      font-family: \"Circular Std\";\n      font-size: 12px;\n      font-style: normal;\n      font-weight: 450;\n      line-height: normal;\n      margin-bottom: 0px;\n    }\n  }\n  .footerlinkswrapper {\n    display: flex;\n    justify-content: center;\n    .footerlinks {\n      display: flex;\n      gap: 10px;\n      align-items: center;\n      margin-bottom: 0px;\n      li {\n        list-style: none;\n        a {\n            color: #3C3C3C;\n            font-family: \"Circular Std\";\n            font-size: 12px;\n            font-style: normal;\n            font-weight: 450;\n            line-height: normal;\n          &:hover {\n            color: #007bff;\n          }\n        }\n      }\n    }\n    .socialLinks {\n      display: flex;\n      gap: 10px;\n      align-items: center;\n      margin-bottom: 0px;\n      li {\n        list-style: none;\n        a {\n          text-decoration: none;\n          color: #000;\n          font-size: 14px;\n          font-weight: 500;\n          &:hover {\n            color: #007bff;\n          }\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
