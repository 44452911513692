import React, { useState, useEffect } from "react";

import "./college.scss";
import axios from "axios";
import base from "../../../Config/config";
// import userAvatar from "../../../Assets/Images/user-avatar.png";
import collegeLogo1 from "../../../Assets/Images/uni-logo.jpg";
import collegeLogo2 from "../../../Assets/Images/uni-logo2.png";
import collegeLogo3 from "../../../Assets/Images/uni-logo3.png";
import Navbar from "../../../Components/Navbar/Navbar";
import Sidebar from "../../../Components/Sidebar/sidebar";
import { Link } from "react-router-dom";
import Footer from "../../../Components/Footer/Footer";
import { CircularProgress } from "@mui/material";

const College = () => {
  const [activeTab, setActiveTab] = useState("tab1");
  const [universities, setUniversities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleFileUpload = async () => {
    if (file) {
      const formData = new FormData();
      formData.append("excelFile", file);
      try {
        setLoading(true);
        const token = localStorage.getItem("token");
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        await axios.post(
          `${base.BASE_URL}/uploadExcel`,
          formData,
          config
        );
        alert("File uploaded successfully");

        setShowModal(false);
      } catch (error) {
        alert("File upload failed");
      } finally {
        window.location.reload();
        setLoading(false);
      }
    }
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const token = localStorage.getItem("token");
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        const response = await axios.get(
          `${base.BASE_URL}/university/get`,
          config
        );
        setUniversities(response.data.data.universities);
        console.log(response.data.data.universities);
      } catch (error) {
        console.error("Error fetching profile data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProfile();
  }, []);

  const cardsData = [
    {
      id: 1,
      userimg: collegeLogo1,
      collegename: "Adrian College",
      location: "Adrian, MI",
      details: "Private nonprofit • 1,741 students • $39,107",
      skills: ["NCAA DIII", "Michigan (MIAA)", "Football"],
      couches: "Coaches: Joe Palka and 11 others",
      sport: "Football",
      statuss: "Pending",
      // btns: ["More Info", "Manage Process"],
    },
    {
      id: 2,
      userimg: collegeLogo2,
      collegename: "Adrian College",
      location: "Adrian, MI",
      details: "Private nonprofit • 1,741 students • $39,107",
      skills: ["NCAA DIII", "Michigan (MIAA)", "Football"],
      couches: "Coaches: Joe Palka and 11 others",
      sport: "Football",
      statuss: "Intrested",
      // btns: ["More Info", "Manage Process"],
    },
    {
      id: 3,
      userimg: collegeLogo3,
      collegename: "Adrian College",
      location: "Adrian, MI",
      details: "Private nonprofit • 1,741 students • $39,107",
      skills: ["NCAA DIII", "Michigan (MIAA)", "Football"],
      couches: "Coaches: Joe Palka and 11 others",
      sport: "Football",
      statuss: "Pending",
      // btns: ["More Info", "Manage Process"],
    },
  ];

  if (loading) {
    return (
      <div
        className="loader-container d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <CircularProgress />
      </div>
    );
  }

  const handleDelete = async (collegeid) => {
    try {
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.delete(
        `${base.BASE_URL}/university/deleteUniversity/${collegeid}`,
        config
      );
  
      if (response.status === 200) {
        console.log("College deleted successfully");
      }
    } catch (error) {
      console.error("Error deleting college:", error);
    } finally {
      window.location.reload();
    }
  };
  

  return (
    <>
      <div className="college-wrapper">
        <div className="container-fluid">
          <Navbar />
          <div className="row">
            <div className="col-lg-2">
              <Sidebar />
            </div>
            <div className="col-lg-10">
              <div className="filteration-wrapper">
                <h4 className="collegepage-title">Search Colleges</h4>
                <div className="tabs-wrap">
                  <div className="row">
                    <div className="col-lg-1">
                      <div className="tab-box">
                        <div
                          className={`tab ${
                            activeTab === "tab1" ? "active" : ""
                          }`}
                        >
                          <input
                            type="radio"
                            id="tab1"
                            name="tab"
                            onClick={() => handleTabClick("tab1")}
                          />
                          <label htmlFor="tab1">Search</label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-1">
                      <div className="tab-box">
                        <div
                          className={`tab ${
                            activeTab === "tab2" ? "active" : ""
                          }`}
                        >
                          <input
                            type="radio"
                            id="tab2"
                            name="tab"
                            onClick={() => handleTabClick("tab2")}
                          />
                          <label htmlFor="tab2">Favorites</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={`tab-content ${
                    activeTab === "tab1" ? "active" : ""
                  }`}
                >
                  <div className="filter-wrap">
                    <div className="row">
                      <div className="col-lg-1">
                        <div className="filter-box form-group">
                          <select className="filter-select form-control">
                            <option value="select" className="first">
                              Sports
                            </option>
                            <option value="btech">B.Tech</option>
                            <option value="mtech">M.Tech</option>
                            <option value="bca">BCA</option>
                            <option value="mca">MCA</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-1">
                        <div className="filter-box form-group">
                          <select className="filter-select form-control">
                            <option value="select" className="first">
                              Davison
                            </option>
                            <option value="cse">CSE</option>
                            <option value="ece">ECE</option>
                            <option value="mech">Mechanical</option>
                            <option value="civil">Civil</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-2">
                        <div className="filter-box">
                          <input
                            type="text"
                            className="filter-input form-control"
                            placeholder="Conference"
                          />
                        </div>
                      </div>
                      <div className="col-lg-2">
                        <div className="filter-box form-group">
                          <select className="filter-select form-control">
                            <option value="select" className="first">
                              Fields of study
                            </option>
                            <option value="cse">CSE</option>
                            <option value="ece">ECE</option>
                            <option value="mech">Mechanical</option>
                            <option value="civil">Civil</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-2">
                        <div className="filter-box form-group">
                          <select className="filter-select form-control">
                            <option value="select" className="first">
                              Type of school
                            </option>
                            <option value="cse">CSE</option>
                            <option value="ece">ECE</option>
                            <option value="mech">Mechanical</option>
                            <option value="civil">Civil</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-2">
                        <div className="filter-box form-group">
                          <select className="filter-select form-control">
                            <option value="select" className="first">
                              Locale
                            </option>
                            <option value="cse">CSE</option>
                            <option value="ece">ECE</option>
                            <option value="mech">Mechanical</option>
                            <option value="civil">Civil</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-2">
                        <div className="filter-box form-group">
                          <select className="filter-select form-control">
                            <option value="select" className="first">
                              Religious Affiliations
                            </option>
                            <option value="cse">CSE</option>
                            <option value="ece">ECE</option>
                            <option value="mech">Mechanical</option>
                            <option value="civil">Civil</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="college-btn-wrap">
                    <button
                      className="open-modal-btn my-3"
                      onClick={() => setShowModal(true)}
                    >
                      Add College
                    </button>
                  </div>
                  <div className="row">
                    {universities?.map((card) => {
                      {
                        console.log(card);
                      }
                      return (
                        <div className="col-lg-4">
                          <div className="cards-container">
                            <div className="card-wrap">
                              <div className="profile-about">
                                <div className="profile-img">
                                  <img src={card.displayPicture} alt="" />
                                </div>
                                <div className="profile-info">
                                  {card?.general?.school ? (
                                    <h4>{card?.general?.school}</h4>
                                  ) : (
                                    ""
                                  )}
                                  {card.general ? (
                                    <p>
                                      {card.general.city},{card.general.state}
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                  {/* <p>
                                    {card.general.city},{card.general.state}
                                  </p> */}
                                  <p>
                                    {card.academics.map((det) => {
                                      return (
                                        <p>
                                          {det.level}, {det.predominant_degree},
                                          {/* {det.fields_of_study.map((field) => {
                                            return <p>{field}</p>;
                                          })} */}
                                        </p>
                                      );
                                    })}
                                  </p>
                                </div>
                              </div>
                              <div className="profile-skills">
                                {card.athletics.sports_offered.map(
                                  (athlete_sport) => {
                                    return (
                                      <div className="skill">
                                        <p>{athlete_sport}</p>
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                              <div className="coaches">
                                <p>
                                  Coaches: {card.coaches[0]?.first_name} and{" "}
                                  {card.coaches.length} others
                                </p>
                              </div>
                              <div className="pro-cards-btns-wrapper">
                                <div className="pro-cards-btns">
                                  <Link
                                    to={`/college/${card.universityId}`}
                                    className="college-link"
                                  >
                                    <button className="pro-btn">
                                      More Info
                                    </button>
                                  </Link>
                                  <button className="pro-btn">
                                    Manage Process
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className="card-edit-btn">
                              <Link to={`/collegeedit/${card.universityId}`}>
                                <button className="edit-btn btn-a">
                                  <i
                                    className="fa fa-pencil-square-o"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                              </Link>
                              <button
                                className="delt-btn btn-a"
                                onClick={() => handleDelete(card.universityId)}
                              >
                                <i
                                  className="fa fa-trash"
                                  aria-hidden="true"
                                ></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div
                  className={`tab-content ${
                    activeTab === "tab2" ? "active" : ""
                  }`}
                >
                  <div className="filter-wrap">
                    <div className="row">
                      <div className="col-lg-1">
                        <div className="filter-box form-group">
                          <select className="filter-select form-control">
                            <option value="select" className="first">
                              Sports
                            </option>
                            <option value="btech">B.Tech</option>
                            <option value="mtech">M.Tech</option>
                            <option value="bca">BCA</option>
                            <option value="mca">MCA</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-1">
                        <div className="filter-box form-group">
                          <select className="filter-select form-control">
                            <option value="select" className="first">
                              Davison
                            </option>
                            <option value="cse">CSE</option>
                            <option value="ece">ECE</option>
                            <option value="mech">Mechanical</option>
                            <option value="civil">Civil</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-2">
                        <div className="filter-box">
                          <input
                            type="text"
                            className="filter-input form-control"
                            placeholder="Conference"
                          />
                        </div>
                      </div>
                      <div className="col-lg-2">
                        <div className="filter-box form-group">
                          <select className="filter-select form-control">
                            <option value="select" className="first">
                              Fields of study
                            </option>
                            <option value="cse">CSE</option>
                            <option value="ece">ECE</option>
                            <option value="mech">Mechanical</option>
                            <option value="civil">Civil</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-2">
                        <div className="filter-box form-group">
                          <select className="filter-select form-control">
                            <option value="select" className="first">
                              Type of school
                            </option>
                            <option value="cse">CSE</option>
                            <option value="ece">ECE</option>
                            <option value="mech">Mechanical</option>
                            <option value="civil">Civil</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-2">
                        <div className="filter-box form-group">
                          <select className="filter-select form-control">
                            <option value="select" className="first">
                              Locale
                            </option>
                            <option value="cse">CSE</option>
                            <option value="ece">ECE</option>
                            <option value="mech">Mechanical</option>
                            <option value="civil">Civil</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-2">
                        <div className="filter-box form-group">
                          <select className="filter-select form-control">
                            <option value="select" className="first">
                              Religious Affiliations
                            </option>
                            <option value="cse">CSE</option>
                            <option value="ece">ECE</option>
                            <option value="mech">Mechanical</option>
                            <option value="civil">Civil</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th scope="col">College</th>
                            <th scope="col">Sport</th>
                            <th scope="col">Status</th>
                            <th scope="col">Change Rank</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {cardsData.map((card) => {
                            return (
                              <tr>
                                <td>
                                  <div className="uni-det">
                                    <div className="uni-img">
                                      <img src={card.userimg} alt="" />
                                    </div>
                                    <p>
                                      {card.collegename}
                                      <br />
                                      <span>{card.location}</span>
                                    </p>
                                  </div>
                                </td>
                                <td>
                                  <p className="sport">{card.sport}</p>
                                </td>
                                <td>
                                  <p className="status">{card.statuss}</p>
                                </td>
                                <td>
                                  <p>
                                    <i
                                      className="fa fa-arrow-up"
                                      aria-hidden="true"
                                    ></i>
                                    <i
                                      className="fa fa-arrow-down"
                                      aria-hidden="true"
                                    ></i>
                                  </p>
                                </td>
                                <td>
                                  <button className="btn btn-a">
                                    <i className="fa fa-cog" aria-hidden="true"></i>
                                  </button>
                                  <button className="btn btn-a">
                                    <i
                                      className="fa fa-trash"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showModal && (
        <div className="modall">
          <div className="modall-content">
            <span className="close" onClick={() => setShowModal(false)}>
              &times;
            </span>
            <h4 className="dashpage-title">Upload Csv For Adding College</h4>
            <input type="file" onChange={handleFileChange} />
            <div className="up-btn-wrap">
              <button onClick={handleFileUpload} className="upload-btn">
                Upload
              </button>
            </div>
          </div>
        </div>
      )}
      <Footer />
    </>
  );
};

export default College;
