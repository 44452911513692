import React from "react";
import "./dashhome.scss";
import Navbar from "../../../Components/Navbar/Navbar";
import Sidebar from "../../../Components/Sidebar/sidebar";
import DashHomeBox from "../../../Components/PageComponents/DashHomeBox/DashHomeBox";
import LatestNews from "../../../Components/PageComponents/LatestNews/LatestNews";
import DiscoverNews from "../../../Components/PageComponents/DiscoverNews/DiscoverNews";
import Footer from "../../../Components/Footer/Footer";

const DashHome = () => {
  return (
    <>
      <div className="dashpage-wrapper">
        <div className="container-fluid">
          <Navbar />
          <div className="row">
            <div className="col-lg-2">
              <Sidebar />
            </div>
            <div className="col-lg-7">
              <h4 className="dashpage-title">Dashboard</h4>
              <div className="row">
                <DashHomeBox />
              </div>
              <div className="row">
              <DiscoverNews/>
              </div>
            </div>
            <div className="col-lg-3">
              <LatestNews />
            </div>
          </div>
          
        </div>
      </div>
      <Footer/>
    </>
  );
};

export default DashHome;
