import React, { useState } from "react";
import "./signupform.scss";
import LoginHeader from "../../Components/LoginHeader/LoginHeader";
import Footer from "../../Components/Footer/Footer";
import pulse from "../../Assets/Images/pulse-bg.png";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import base from "../../Config/config";

const SignupForm = () => {
  const [formData, setFormData] = useState({
    fullName: "",
    phoneNumber: "",
    email: "",
    password: "",
    userCategory: "",
  });
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
       `${base.BASE_URL}/users/register/`,
        formData
      );
      if (response.status === 201) {
        toast.success("Login successful!");
        setSuccess("Registration successful!");
        setError(null);
        navigate("/");
      }
    } catch (error) {
      setError(error.response.data.message || "Something went wrong!");
      toast.error(error.response?.data?.message || "Something went wrong!");
      setSuccess(null);
    } finally {
      setLoading(false);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };


  return (
    <>
      <LoginHeader />
      <div className="forms">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 left">
              <div className="pulse-wrapper">
                <div className="pulse">
                  <img src={pulse} alt="" />
                </div>
              </div>
              <div className="form-wrapper">
                <h1>Sign Up</h1>
                <p>
                  4 Coaches online is a platform, Where you should find multiple
                  university Against your friends all season.
                </p>
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <select
                      className="form-control"
                      name="userCategory"
                      value={formData.userCategory}
                      onChange={handleChange}
                    >
                      <option value="">Select Category</option>
                      <option value="College Coach">College Coach</option>
                      <option value="Highschool Coach">Highschool Coach</option>
                      <option value="athlete">Athlete</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      name="username"
                      value={formData.username}
                      onChange={handleChange}
                      placeholder="Your Full Name"
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      name="phoneNumber"
                      value={formData.phoneNumber}
                      onChange={handleChange}
                      placeholder="Phone Number"
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      placeholder="Email"
                    />
                  </div>
                  <div className="form-group password-wrapper">
                  <input
                      type={showPassword ? "text" : "password"}
                      className="form-control"
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                      placeholder="Password"
                    />
                    <span className="password-toggle-icon" onClick={togglePasswordVisibility}>
                      <i className={showPassword ? "fa fa-eye-slash" : "fa fa-eye"} />
                    </span>
                  </div>
                  <div className="form-group">
                    <button type="submit" className="btn btn-a">
                      {loading ? "Loading..." : "Submit"}
                      {!loading && (
                        <i className="fa fa-arrows-h" aria-hidden="true"></i>
                      )}
                    </button>
                  </div>
                </form>
                {error && <p className="error-message">{error}</p>}
                {success && <p className="success-message">{success}</p>}
                <p>
                  <Link to="/">Already have an Account?</Link>
                </p>
              </div>
            </div>
            <div className="col-lg-8 right"></div>
          </div>
        </div>
      </div>
      <Footer />
      <ToastContainer />
    </>
  );
};

export default SignupForm;
