import axios from "axios";
import base from "../../Config/config"

export const getProfileInfoRequest = () => ({
  type: "PROFILEINFO_REQUEST"
});

export const getProfileInfoSuccess = profileInfo => ({
  type: "PROFILEINFO_SUCCESS",
  payload: profileInfo
});

export const getProfileInfoFailure = error => ({
  type: "PROFILEINFO_FAILURE",
  payload: error
});

export const getProfileInfo = () => async dispatch => {
  dispatch(getProfileInfoRequest());
  try {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    const response = await axios.get(
      `${base.BASE_URL}/sportProfile/get`,
      config
    );

    // console.log("Profile info fetch response:", response.data.data.sportProfiles);
    // localStorage.removeItem("token");
    dispatch(getProfileInfoSuccess(response.data.data.sportProfiles));
  } catch (error) {
    console.error("User info fetch error:", error);
    dispatch(getProfileInfoFailure(error.message));
  }
};
