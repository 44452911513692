import React from "react";
import "./dashhomebox.scss";

const DashHomeBox = () => {
  const widgetDetails = [
    {
      icon: "fa fa-graduation-cap",
      count: 34,
      title: "College Favorites",
      description: "Across entire org, last 30 days",
    },
    {
      icon: "fa fa-envelope",
      count: 34,
      title: "College Favorites",
      description: "Across entire org, last 30 days",
    },
    {
      icon: "fa fa-users",
      count: 34,
      title: "College Favorites",
      description: "Across entire org, last 30 days",
    },
    {
      icon: "fa fa-steam-square",
      count: 34,
      title: "College Favorites",
      description: "Across entire org, last 30 days",
    },
  ];

  return (
    <>
      {widgetDetails.map((widget, index) => (
        <div className="col-lg-6" key={index}>
          <div className="dashhome-box">
            <div className="dashhome-box-top">
              <div className="icon-wrap">
                <i className={widget.icon}></i>
              </div>
              <div className="content-wrap">
                <h2>{widget.count}</h2>
              </div>
            </div>
            <div className="details-wrap">
              <h4>{widget.title}</h4>
              <p>{widget.description}</p>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default DashHomeBox;
