import React, { useState } from "react";
import "./forgotpassword.scss";
import base from "../../Config/config";
import axios from "axios";
import LoginHeader from "../../Components/LoginHeader/LoginHeader";
import Footer from "../../Components/Footer/Footer";
import pulse from "../../Assets/Images/pulse-bg.png";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const data = {
        email: event.target.email.value,
      };

      await axios.post(`${base.BASE_URL}/users/forgotPassword`, data, config);
      toast.success("Forgot password email send successfully");
    } catch (error) {
      toast.error(error.response.data.message)
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
    <ToastContainer/>
      <LoginHeader />
      <div className="forms">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 left">
              <div className="pulse-wrapper">
                <div className="pulse">
                  <img src={pulse} alt="" />
                </div>
              </div>
              <div className="form-wrapper">
                <h1>Forgot Password</h1>
                <p>Enter your email below, we’ll send you the rest Link.</p>
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <input
                      type="email"
                      name="email"
                      className="form-control"
                      placeholder="Email"
                    />
                  </div>

                  <div className="form-group">
                    <button type="submit" className="btn btn-a">
                      Reset Link
                      <i className="fa fa-arrows-h" aria-hidden="true"></i>
                    </button>
                  </div>
                </form>
                <p>
                  <Link to="/">Back To Login</Link>
                </p>
              </div>
            </div>
            <div className="col-lg-8 right"></div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ForgotPassword;
