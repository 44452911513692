import React, { useState, useEffect } from "react";
import "./navbar.scss";
import { useImpersonation } from "../../contexts/ImpersonationContext";
import logo from "../../Assets/Images/logo.png";
import person from "../../Assets/Images/person.png";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import base from "../../Config/config";
import Sidebar from "../Sidebar/sidebar";

const Navbar = () => {
  const username = localStorage.getItem("name");

  const [collapsed, setCollapsed] = useState(true);
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    document.body.classList.toggle("ovr-hiddn");
    document.body.classList.toggle("overflw");
  };
  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  useEffect(() => {
    return () => {
      document.body.classList.remove("ovr-hiddn");
      document.body.classList.remove("overflw");
    };
  }, []);

  const handleLogout = async () => {
    try {
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      await axios.post(`${base.BASE_URL}/users/logout`, null, config);
      localStorage.removeItem("token");
      localStorage.removeItem("role");
      localStorage.removeItem("name");
      localStorage.removeItem("isImpersonating");
      localStorage.removeItem("originalToken");
      localStorage.removeItem("originalName");
      localStorage.removeItem("originalRole");
      localStorage.removeItem("impersonationName");
      localStorage.removeItem("impersonationRole");
      navigate("/");
    } catch (error) {
      console.error("Logout error:", error);
    }
  };

  const { isImpersonating, handleStopImpersonating } = useImpersonation();

  const handleStopImpersonationClick = () => {
    if (isImpersonating) {
      handleStopImpersonating();
    }
  };

  return (
    <>
      {isImpersonating && (
        <button
          className="btn btn-danger my-3"
          onClick={handleStopImpersonationClick}
        >
          Stop Impersonating
        </button>
      )}
      <div className="navbar-wrapper">
        <div className="container-fluid">
          <div
            className={`menu-Bar ${menuOpen ? "open" : ""}`}
            onClick={toggleMenu}
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
          <div className="row">
            <div className="col-md-12 col-lg-2 ">
              <div className="navbar-logo-wrapper">
                <img src={logo} alt="four couches logo" />
              </div>
            </div>
            <div className="col-md-0 col-lg-7">
              <div className="navbar-search-wrapper">
                <input type="text" placeholder="Search" />
              </div>
            </div>
            <div className="col-md-0 col-lg-3">
              <div className={`menu-overlay ${menuOpen ? "active" : ""}`}></div>
              <div className={`menuWrap ${menuOpen ? "open" : ""}`}>
                {/* <ul className="menu">
                  <li>
                    <Link to="/">
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link to="/">Services</Link>
                  </li>
                  <li>
                    <Link to="/">About Us</Link>
                  </li>
                  <li>
                    <Link to="/">Community</Link>
                  </li>
                  <li>
                    <Link to="/">Shop</Link>
                  </li>
                </ul> */}
                <div className="menu">
                  <Sidebar />
                </div>
                <div className="navbar-links-wrapper">
                  <div className="help-supp">
                    <Link to="/">
                      <p>
                        <i
                          className="fa fa-question-circle-o"
                          aria-hidden="true"
                        ></i>
                        {"\u00A0"}
                        Help? Support
                      </p>
                    </Link>
                  </div>
                  <div className="bell-icon">
                    <i className="fa fa-bell" aria-hidden="true"></i>
                  </div>
                  <div className="profile-wrap" onClick={toggleCollapse}>
                    <div className="profile-wrapper">
                      <div className="profile-pic">
                        <img
                          src={person}
                          alt="user profile picture"
                          className="profile-image"
                        />
                      </div>
                      <div className="profile-details">
                        <p>
                          <span>Welcome!</span>
                          <br />
                          {username ? username : ""}
                        </p>
                      </div>
                    </div>
                    <div className="profile-icon-wrap">
                      <i className="fa fa-sort-desc" aria-hidden="true"></i>
                    </div>
                    <div
                      className={`logout-btn-wrap ${
                        collapsed ? "collapse" : "collapse show"
                      }`}
                    >
                      {isImpersonating ? (
                        <></>
                      ) : (
                        <button className="logout-btn" onClick={handleLogout}>
                          Logout
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="navbar-links-wrapper desk">
                <div className="help-supp">
                  <Link to="/">
                    <p>
                      <i className="fa fa-question-circle-o" aria-hidden="true"></i>
                      {"\u00A0"}
                      Help? Support
                    </p>
                  </Link>
                </div>
                <div className="bell-icon">
                  <i className="fa fa-bell" aria-hidden="true"></i>
                </div>
                <div className="profile-wrap" onClick={toggleCollapse}>
                  <div className="profile-wrapper">
                    <div className="profile-pic">
                      <img
                        src={person}
                        alt="user profile picture"
                        className="profile-image"
                      />
                    </div>
                    <div className="profile-details">
                      <p>
                        <span>Welcome!</span>
                        <br />
                        {username ? username : ""}
                      </p>
                    </div>
                  </div>
                  <div className="profile-icon-wrap">
                    <i className="fa fa-sort-desc" aria-hidden="true"></i>
                  </div>
                  <div
                    className={`logout-btn-wrap ${
                      collapsed ? "collapse" : "collapse show"
                    }`}
                  >
                    {isImpersonating ? (
                      <></>
                    ) : (
                      <button className="logout-btn" onClick={handleLogout}>
                        Logout
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <button onClick={toggleCollapse}>Collapsible</button>
      <div className={collapsed ? "collapse" : "collapse show"}>
        Lorem ipsum dolor text....
      </div> */}
    </>
  );
};

export default Navbar;
