// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filteration-wrapper .impression {
  display: inline-flex;
  padding: 6px 12.6px 6px 14px;
  justify-content: center;
  align-items: flex-start;
  gap: 3px;
  border-radius: 100px;
  background: #f5f5f5;
  color: #849aab;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Circular Std";
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
  cursor: pointer;
}
.filteration-wrapper tr:hover .upload {
  color: #49EF45;
}
.filteration-wrapper tr:hover .delete {
  color: #FA5AAB;
}
.filteration-wrapper tr:hover .setting {
  color: #3292FD;
}
.filteration-wrapper tr:hover .impression {
  background: linear-gradient(91deg, #4C6BCB 0.68%, #3F8EE6 99.43%);
  color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Dashboard/AccountsPage/accountspage.scss"],"names":[],"mappings":"AACE;EACE,oBAAA;EACA,4BAAA;EACA,uBAAA;EACA,uBAAA;EACA,QAAA;EACA,oBAAA;EACA,mBAAA;EACA,cAAA;EACA,6CAAA;EACA,2BAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,eAAA;AAAJ;AAIM;EACE,cAAA;AAFR;AAIM;EACE,cAAA;AAFR;AAIM;EACE,cAAA;AAFR;AAIM;EACE,iEAAA;EACA,WAAA;AAFR","sourcesContent":[".filteration-wrapper {\n  .impression {\n    display: inline-flex;\n    padding: 6px 12.6px 6px 14px;\n    justify-content: center;\n    align-items: flex-start;\n    gap: 3px;\n    border-radius: 100px;\n    background: #f5f5f5;\n    color: #849aab;\n    font-feature-settings: \"clig\" off, \"liga\" off;\n    font-family: \"Circular Std\";\n    font-size: 14px;\n    font-style: normal;\n    font-weight: 450;\n    line-height: normal;\n    cursor: pointer;\n  }\n  tr{\n      &:hover{\n      .upload{\n        color: #49EF45;\n      }\n      .delete{\n        color: #FA5AAB;\n      }\n      .setting{\n        color: #3292FD;\n      }\n      .impression{\n        background: linear-gradient(91deg, #4C6BCB 0.68%, #3F8EE6 99.43%);\n        color: #fff;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
