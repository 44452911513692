import React, { useState, useEffect } from "react";
import axios from "axios";
import base from "../../../Config/config"
import collegeLogo1 from "../../../Assets/Images/uni-logo.jpg";
import handshake from "../../../Assets/Images/handshake-icon.png";

import { useParams } from "react-router-dom";
import Navbar from "../../../Components/Navbar/Navbar";
import Sidebar from "../../../Components/Sidebar/sidebar";
import {
  CircularProgress,
  Modal,
  Box,
  Typography,
  Button,
  TextField,
} from "@mui/material";
import "./collegeedit.scss";
import "../College/college.scss";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const CollegeEdit = () => {
  const [university, setUniversity] = useState({});
  const [loading, setLoading] = useState(true);
  const [editedGeneral, setEditedGeneral] = useState({
    type: "",
    school: "",
    website: "",
    team_twitter: "",
    team_facebook: "",
    team_instagram: "",
    city: "",
    state: "",
    locale: "",
  });

  const [editedAcademic, setEditedAcademic] = useState({});
  const [editedAthletics, setEditedAthletics] = useState({});
  const [editedCoach, setEditedCoach] = useState({});
  const [editedMetrics, setEditedMetrics] = useState({});
  const [selectedCoachIndex, setSelectedCoachIndex] = useState(null);
  const [isGeneralModalOpen, setIsGeneralModalOpen] = useState(false);
  const [isAcademicModalOpen, setIsAcademicModalOpen] = useState(false);
  const [isAthleticsModalOpen, setIsAthleticsModalOpen] = useState(false);
  const [isCoachModalOpen, setIsCoachModalOpen] = useState(false);
  const [isMetricsModalOpen, setIsMetricsModalOpen] = useState(false);
  const [selectedAcademicId, setSelectedAcademicId] = useState(null);

  const { collegeId } = useParams();

  useEffect(() => {
    const fetchCollege = async () => {
      try {
        const token = localStorage.getItem("token");
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        const response = await axios.get(
          `${base.BASE_URL}/university/get?_id=${collegeId}`,
          config
        );
        setUniversity(response.data.data.universities[0]);
      } catch (error) {
        console.error("Error fetching profile data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCollege();
  }, [collegeId]);

  const handleOpenGeneralModal = () => {
    setEditedGeneral(university.general);
    setIsGeneralModalOpen(true);
  };

  const handleCloseGeneralModal = () => {
    setIsGeneralModalOpen(false);
  };

  const handleOpenAcademicModal = (academic) => {
    setEditedAcademic(academic);
    setSelectedAcademicId(academic._id);
    setIsAcademicModalOpen(true);
  };

  const handleCloseAcademicModal = () => {
    setIsAcademicModalOpen(false);
  };

  const handleOpenAthleticsModal = () => {
    setEditedAthletics(university.athletics);
    setIsAthleticsModalOpen(true);
  };

  const handleCloseAthleticsModal = () => {
    setIsAthleticsModalOpen(false);
  };

  const handleGeneralInputChange = (e) => {
    const { name, value } = e.target;
    setEditedGeneral({ ...editedGeneral, [name]: value });
  };

  const handleAcademicInputChange = (e) => {
    const { name, value } = e.target;
    setEditedAcademic({ ...editedAcademic, [name]: value });
  };

  const handleAthleticsInputChange = (e) => {
    const { name, value } = e.target;
    setEditedAthletics({ ...editedAthletics, [name]: value });
  };

  const handleOpenCoachModal = (coach, index) => {
    setEditedCoach({ ...coach, id: coach._id });
    setSelectedCoachIndex(index);
    setIsCoachModalOpen(true);
  };

  const handleCloseCoachModal = () => {
    setIsCoachModalOpen(false);
  };

  const handleCoachInputChange = (e) => {
    const { name, value } = e.target;
    setEditedCoach({ ...editedCoach, [name]: value });
  };

  const handleOpenMetricsModal = () => {
    setEditedMetrics(university.metrics);
    setIsMetricsModalOpen(true);
  };

  const handleCloseMetricsModal = () => {
    setIsMetricsModalOpen(false);
  };

  const handleSaveMetricsChanges = async () => {
    try {
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.put(
        `${base.BASE_URL}/university/updateUniMetrics/${collegeId}`,
        editedMetrics,
        config
      );

      if (response.status === 200) {
        setUniversity((prev) => ({
          ...prev,
          metrics: editedMetrics,
        }));
        setIsMetricsModalOpen(false);
      }
    } catch (error) {
      console.error("Error updating metrics information:", error);
    }
  };

  const handleMetricsInputChange = (e) => {
    const { name, value } = e.target;
    setEditedMetrics({ ...editedMetrics, [name]: value });
  };

  const handleSaveCoachChanges = async () => {
    try {
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const coachToUpdate = { ...editedCoach };
      delete coachToUpdate._id; // Remove _id before sending to the API

      const response = await axios.put(
        `${base.BASE_URL}/university/updateCoachInUni/${collegeId}`,
        coachToUpdate,
        config
      );

      if (response.status === 200) {
        setUniversity((prev) => {
          const updatedCoaches = [...prev.coaches];
          updatedCoaches[selectedCoachIndex] = editedCoach;
          return {
            ...prev,
            coaches: updatedCoaches,
          };
        });
        setIsCoachModalOpen(false);
      }
    } catch (error) {
      console.error("Error updating coach information:", error);
    }
  };

  const handleSaveGeneralChanges = async () => {
    try {
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.put(
        `${base.BASE_URL}/university/updateGeneral/${collegeId}`,
        editedGeneral,
        config
      );
      setUniversity((prev) => ({
        ...prev,
        general: editedGeneral,
      }));

      console.log(response);
      setIsGeneralModalOpen(false);
    } catch (error) {
      console.error("Error updating general information:", error);
    } finally {
      window.location.reload();
    }
  };

  const handleSaveAcademicChanges = async () => {
    try {
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const academicToUpdate = {
        ...editedAcademic,
        id: selectedAcademicId,
      };

      const response = await axios.put(
        `${base.BASE_URL}/university/updateAcademicInUni/${collegeId}`,
        academicToUpdate,
        config
      );

      if (response.status === 200) {
        setUniversity((prev) => ({
          ...prev,
          academics: prev.academics.map((academic) =>
            academic._id === selectedAcademicId ? editedAcademic : academic
          ),
        }));
        setIsAcademicModalOpen(false);
      }
    } catch (error) {
      console.error("Error updating academic information:", error);
    } finally {
      window.location.reload();
    }
  };

  const handleSaveAthleticsChanges = async () => {
    try {
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.put(
        `${base.BASE_URL}/university/updateUniAthletics/${collegeId}`,
        editedAthletics,
        config
      );
      setUniversity((prev) => ({
        ...prev,
        athletics: editedAthletics,
      }));
      setIsAthleticsModalOpen(false);
    } catch (error) {
      console.error("Error updating athletics information:", error);
    } finally {
      window.location.reload();
    }
  };

  if (loading) {
    return (
      <div
        className="loader-container d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="college-parti">
      <div className="container-fluid">
        <Navbar />
        <div className="row">
          <div className="col-lg-2">
            <Sidebar />
          </div>
          <div className="col-lg-10">
            <div className="row">
              <div className="college-banner">{/* Banner content */}</div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="general-wrap">
                  <div className="genral-head">
                    <h3>General Information</h3>
                  </div>
                  <div className="general-content">
                    <div className="general-content-skills">
                      <ul>
                        <li>
                          <h4>Type:</h4>
                          <p>{university.general.type}</p>
                        </li>
                        <li>
                          <h4>Website:</h4>
                          {university.general.website === "-" ? (
                            <p>No available link</p>
                          ) : (
                            <a
                              href={university.general.website}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <p>{university.general.website}</p>
                            </a>
                          )}
                        </li>
                        <li>
                          <h4>Team Twitter Url:</h4>
                          {university.general.team_twitter === "-" ? (
                            <p>No available link</p>
                          ) : (
                            <a
                              href={university.general.team_twitter}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <p>{university.general.team_twitter}</p>
                            </a>
                          )}
                        </li>
                        <li>
                          <h4>Team Instagram Url:</h4>
                          {university.general.team_instagram === "-" ? (
                            <p>No available link</p>
                          ) : (
                            <a
                              href={university.general.team_instagram}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <p>{university.general.team_instagram}</p>
                            </a>
                          )}
                        </li>
                        <li>
                          <h4>Team Facebook Url:</h4>
                          {university.general.team_facebook === "-" ? (
                            <p>No available link</p>
                          ) : (
                            <a
                              href={university.general.team_facebook}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <p>{university.general.team_facebook}</p>
                            </a>
                          )}
                        </li>
                        <li>
                          <h4>Location:</h4>
                          <p>
                            {university.general.city +
                              ", " +
                              university.general.state +
                              ", " +
                              university.general.locale}
                          </p>
                        </li>
                        <li>
                          <h4>Locale:</h4>
                          <p>{university.general.locale}</p>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="edit-btn-wrap">
                    <button onClick={handleOpenGeneralModal}>
                      <i
                        className="fa fa-pencil-square-o"
                        aria-hidden="true"
                      ></i>
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="academic-wrap">
                  <div className="academic-head">
                    <h3>Academic Information</h3>
                  </div>
                  <div className="academic-content">
                    <div className="academic-content-skills">
                      {university?.academics?.map((academic) => {
                        return (
                          <React.Fragment key={academic._id}>
                            <ul>
                              <li>
                                <h4>Level:</h4>
                                <p>{academic?.level}</p>
                              </li>
                              <li>
                                <h4>Predominant Degree:</h4>
                                <p>{academic?.predominant_degree}</p>
                              </li>
                            </ul>
                            <div className="field-study">
                              <h4>Field of Study:</h4>
                              <div className="fields">
                                {academic.fields_of_study.map(
                                  (field, index) => {
                                    return <span key={index}>{field}</span>;
                                  }
                                )}
                              </div>
                            </div>
                            <div className="edit-btn-wrap">
                              <button
                                onClick={() =>
                                  handleOpenAcademicModal(academic)
                                }
                              >
                                <i
                                  className="fa fa-pencil-square-o"
                                  aria-hidden="true"
                                ></i>
                              </button>
                            </div>
                          </React.Fragment>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="athletics-wrap">
                    <div className="athletics-head">
                      <h3>Athletics</h3>
                    </div>
                    <div className="athletics-content">
                      <div className="athletics-content-skills">
                        <ul>
                          <li>
                            <h4>Division:</h4>
                            <p>{university.athletics.division}</p>
                          </li>
                          <li>
                            <h4>Conference:</h4>
                            <p>{university.athletics.conference}</p>
                          </li>
                          <li>
                            <h4>Athletic Website:</h4>
                            {university.athletics.athletic_website === "-" ? (
                              <p>No available link</p>
                            ) : (
                              <a
                                href={university.athletics.athletic_website}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <p>{university.athletics.athletic_website}</p>
                              </a>
                            )}
                          </li>
                          <li>
                            <h4>Team Website:</h4>
                            {university.athletics.team_website === "-" ? (
                              <p>No available link</p>
                            ) : (
                              <a
                                href={university.athletics.team_website}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <p>{university.athletics.team_website}</p>
                              </a>
                            )}
                          </li>
                          <li>
                            <h4>Questionnaire:</h4>
                            {university.athletics.sports_offered === "-" ? (
                              <p>No available link</p>
                            ) : (
                              <a
                                href={university.athletics.questionnaire}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <p>{university.athletics.questionnaire}</p>
                              </a>
                            )}
                          </li>
                        </ul>
                        <div className="sports-offer">
                          <h4>Sports Offered:</h4>
                          <div className="fields">
                            {university.athletics.sports_offered.map(
                              (sport) => {
                                return <span>{sport}</span>;
                              }
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="edit-btn-wrap">
                      <button onClick={handleOpenAthleticsModal}>
                        <i
                          className="fa fa-pencil-square-o"
                          aria-hidden="true"
                        ></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="myathletes-wrap">
                    <div className="myathletes-head">
                      <h3>Coaches</h3>
                      <p>
                        The below athletes have an active recruiting process
                        with this program.
                      </p>
                    </div>
                    {university.coaches.map((coach, index) => {
                      return (
                        <div className="athlete-person" key={coach._id}>
                          <div className="about-athlete">
                            <div className="athlete-person-img">
                              <img src={collegeLogo1} alt="college" />
                            </div>
                            <div className="athlete-person-text">
                              <h4>
                                {coach.first_name} {coach.last_name}
                              </h4>
                              <p>
                                {coach.position} • {coach.sports}
                              </p>
                              <p>
                                {coach.email_address} • {coach.phone_number}
                              </p>
                              {coach.individual_twitter === "-" ? (
                                <p>No available link</p>
                              ) : (
                                <a
                                  href={coach.individual_twitter}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <p>{coach.individual_twitter}</p>
                                </a>
                              )}
                              {coach.individual_facebook === "-" ? (
                                <p>No available link</p>
                              ) : (
                                <a
                                  href={coach.individual_facebook}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <p>{coach.individual_facebook}</p>
                                </a>
                              )}
                              {coach.individual_instagram === "-" ? (
                                <p>No available link</p>
                              ) : (
                                <a
                                  href={coach.individual_instagram}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <p>{coach.individual_instagram}</p>
                                </a>
                              )}
                            </div>
                          </div>

                          <div className="edit-btn-wrap">
                            <button
                              onClick={() =>
                                handleOpenCoachModal(coach, coach._id)
                              }
                            >
                              <i
                                className="fa fa-pencil-square-o"
                                aria-hidden="true"
                              ></i>
                            </button>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="metrics-wrapper">
                <div className="row mt-4">
                  <div className="col-lg-12">
                    <div className="metric-head">
                      <h4>Metrics</h4>
                      <div className="edit-btn-wrap">
                        <button onClick={handleOpenMetricsModal}>
                          <i
                            className="fa fa-pencil-square-o"
                            aria-hidden="true"
                          ></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  {Object.entries(university.metrics).map(([key, value]) => (
                    <div className="col-lg-4 my-3" key={key}>
                      <div className="metrics-card">
                        <div className="metrics-card-img">
                          <img src={handshake} alt="college" />
                        </div>
                        <div className="metrics-card-content">
                          <h4>
                            {key
                              .replace(/_/g, " ")
                              .replace(/\b\w/g, (c) => c.toUpperCase())}
                          </h4>
                          <p>{value}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* General Modal */}
      <Modal
        open={isGeneralModalOpen}
        onClose={handleCloseGeneralModal}
        aria-labelledby="general-modal-title"
        aria-describedby="general-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="general-modal-title" variant="h6" component="h2">
            Edit General Information
          </Typography>
          <Box component="form" noValidate autoComplete="off">
            <TextField
              fullWidth
              label="Type"
              name="type"
              value={editedGeneral.type || ""}
              onChange={handleGeneralInputChange}
              margin="normal"
            />
            <TextField
              fullWidth
              label="School"
              name="school"
              value={editedGeneral.school || ""}
              onChange={handleGeneralInputChange}
              margin="normal"
            />
            <TextField
              fullWidth
              label="Website"
              name="website"
              value={editedGeneral.website || ""}
              onChange={handleGeneralInputChange}
              margin="normal"
            />
            <TextField
              fullWidth
              label="Team Twitter Url"
              name="team_twitter"
              value={editedGeneral.team_twitter || ""}
              onChange={handleGeneralInputChange}
              margin="normal"
            />
            <TextField
              fullWidth
              label="Team Instagram Url"
              name="team_instagram"
              value={editedGeneral.team_instagram || ""}
              onChange={handleGeneralInputChange}
              margin="normal"
            />
            <TextField
              fullWidth
              label="Team Facebook Url"
              name="team_facebook"
              value={editedGeneral.team_facebook || ""}
              onChange={handleGeneralInputChange}
              margin="normal"
            />
            <TextField
              fullWidth
              label="City"
              name="city"
              value={editedGeneral.city || ""}
              onChange={handleGeneralInputChange}
              margin="normal"
            />
            <TextField
              fullWidth
              label="State"
              name="state"
              value={editedGeneral.state || ""}
              onChange={handleGeneralInputChange}
              margin="normal"
            />
            <TextField
              fullWidth
              label="Locale"
              name="locale"
              value={editedGeneral.locale || ""}
              onChange={handleGeneralInputChange}
              margin="normal"
            />
          </Box>
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Button onClick={handleCloseGeneralModal} color="secondary">
              Close
            </Button>
            <Button
              onClick={handleSaveGeneralChanges}
              color="primary"
              variant="contained"
              sx={{ ml: 2 }}
            >
              Save changes
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* Academic Modal */}
      <Modal
        open={isAcademicModalOpen}
        onClose={handleCloseAcademicModal}
        aria-labelledby="academic-modal-title"
        aria-describedby="academic-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="academic-modal-title" variant="h6" component="h2">
            Edit Academic Information
          </Typography>
          <Box component="form" noValidate autoComplete="off">
            <TextField
              fullWidth
              label="Level"
              name="level"
              value={editedAcademic.level || ""}
              onChange={handleAcademicInputChange}
              margin="normal"
            />
            <TextField
              fullWidth
              label="Predominant Degree"
              name="predominant_degree"
              value={editedAcademic.predominant_degree || ""}
              onChange={handleAcademicInputChange}
              margin="normal"
            />
            <TextField
              fullWidth
              label="Fields of Study"
              name="fields_of_study"
              value={(editedAcademic.fields_of_study || []).join(", ")}
              onChange={(e) =>
                setEditedAcademic({
                  ...editedAcademic,
                  fields_of_study: e.target.value
                    .split(", ")
                    .map((field) => field.trim()),
                })
              }
              margin="normal"
            />
          </Box>
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Button onClick={handleCloseAcademicModal} color="secondary">
              Close
            </Button>
            <Button
              onClick={handleSaveAcademicChanges}
              color="primary"
              variant="contained"
              sx={{ ml: 2 }}
            >
              Save changes
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* Athletics Modal */}
      <Modal
        open={isAthleticsModalOpen}
        onClose={handleCloseAthleticsModal}
        aria-labelledby="athletics-modal-title"
        aria-describedby="athletics-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="athletics-modal-title" variant="h6" component="h2">
            Edit Athletics Information
          </Typography>
          <Box component="form" noValidate autoComplete="off">
            <TextField
              fullWidth
              label="Division"
              name="division"
              value={editedAthletics.division || ""}
              onChange={handleAthleticsInputChange}
              margin="normal"
            />
            <TextField
              fullWidth
              label="Conference"
              name="conference"
              value={editedAthletics.conference || ""}
              onChange={handleAthleticsInputChange}
              margin="normal"
            />
            <TextField
              fullWidth
              label="Athletic Website"
              name="athletic_website"
              value={editedAthletics.athletic_website || ""}
              onChange={handleAthleticsInputChange}
              margin="normal"
            />
            <TextField
              fullWidth
              label="Team Website"
              name="team_website"
              value={editedAthletics.team_website || ""}
              onChange={handleAthleticsInputChange}
              margin="normal"
            />
            <TextField
              fullWidth
              label="Questionnaire"
              name="questionnaire"
              value={editedAthletics.questionnaire || ""}
              onChange={handleAthleticsInputChange}
              margin="normal"
            />
            <TextField
              fullWidth
              label="Sports Offered"
              name="sports_offered"
              value={(editedAthletics.sports_offered || []).join(", ")}
              onChange={(e) =>
                setEditedAthletics({
                  ...editedAthletics,
                  sports_offered: e.target.value
                    .split(", ")
                    .map((sport) => sport.trim()),
                })
              }
              margin="normal"
            />
          </Box>
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Button onClick={handleCloseAthleticsModal} color="secondary">
              Cancel
            </Button>
            <Button onClick={handleSaveAthleticsChanges} color="primary">
              Save Changes
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* couch modal */}

      <Modal
        open={isCoachModalOpen}
        onClose={handleCloseCoachModal}
        aria-labelledby="coach-modal-title"
        aria-describedby="coach-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="coach-modal-title" variant="h6" component="h2">
            Edit Coach Information
          </Typography>
          <Box component="form" noValidate autoComplete="off">
            <TextField
              fullWidth
              label="First Name"
              name="first_name"
              value={editedCoach.first_name || ""}
              onChange={handleCoachInputChange}
              margin="normal"
            />
            <TextField
              fullWidth
              label="Last Name"
              name="last_name"
              value={editedCoach.last_name || ""}
              onChange={handleCoachInputChange}
              margin="normal"
            />
            <TextField
              fullWidth
              label="Position"
              name="position"
              value={editedCoach.position || ""}
              onChange={handleCoachInputChange}
              margin="normal"
            />
            <TextField
              fullWidth
              label="Sports"
              name="sports"
              value={editedCoach.sports || ""}
              onChange={handleCoachInputChange}
              margin="normal"
            />
            <TextField
              fullWidth
              label="Email Address"
              name="email_address"
              value={editedCoach.email_address || ""}
              onChange={handleCoachInputChange}
              margin="normal"
            />
            <TextField
              fullWidth
              label="Phone Number"
              name="phone_number"
              value={editedCoach.phone_number || ""}
              onChange={handleCoachInputChange}
              margin="normal"
            />
            <TextField
              fullWidth
              label="Individual Twitter"
              name="individual_twitter"
              value={editedCoach.individual_twitter || ""}
              onChange={handleCoachInputChange}
              margin="normal"
            />
            <TextField
              fullWidth
              label="Individual Facebook"
              name="individual_facebook"
              value={editedCoach.individual_facebook || ""}
              onChange={handleCoachInputChange}
              margin="normal"
            />
            <TextField
              fullWidth
              label="Individual Instagram"
              name="individual_instagram"
              value={editedCoach.individual_instagram || ""}
              onChange={handleCoachInputChange}
              margin="normal"
            />
          </Box>
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Button onClick={handleCloseCoachModal} color="secondary">
              Cancel
            </Button>
            <Button onClick={handleSaveCoachChanges} color="primary">
              Save Changes
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* metric */}
      <Modal
        open={isMetricsModalOpen}
        onClose={handleCloseMetricsModal}
        aria-labelledby="metrics-modal-title"
        aria-describedby="metrics-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="metrics-modal-title" variant="h6" component="h2">
            Edit Metrics Information
          </Typography>
          <Box component="form" noValidate autoComplete="off">
            {Object.entries(editedMetrics).map(([key, value]) => (
              <TextField
                fullWidth
                key={key}
                label={key
                  .replace(/_/g, " ")
                  .replace(/\b\w/g, (c) => c.toUpperCase())}
                name={key}
                value={value || ""}
                onChange={handleMetricsInputChange}
                margin="normal"
              />
            ))}
          </Box>
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Button onClick={handleCloseMetricsModal} color="secondary">
              Cancel
            </Button>
            <Button onClick={handleSaveMetricsChanges} color="primary">
              Save Changes
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default CollegeEdit;
