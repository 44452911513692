import React, { useState, useEffect } from "react";
import "./sidebar.scss";
import { Link } from "react-router-dom";

const Sidebar = () => {
  const [activeLink, setActiveLink] = useState("/dashboard");
  const [sidebarData, setSidebarData] = useState([]);

  useEffect(() => {
    const userRole = localStorage.getItem("role");

    let data = [];
    if (userRole === "collegeCoach" || userRole === "highSchoolCoach") {
      data = [
        {
          title: "Dashboard",
          icon: "fa fa-dashboard",
          link: "/dashboard",
        },
        {
          title: "Inbox",
          icon: "fa fa-envelope-o",
          link: "/inbox",
        },
        {
          title: "Account",
          icon: "fa fa-user-circle",
          link: "/accounts",
        },
        {
          title: "Colleges",
          icon: "fa fa-graduation-cap",
          link: "/college",
        },
        // {
        //   title: "Checklists",
        //   icon: "fa fa-list-alt",
        //   link: "/college",
        // },
        {
          title: "Settings",
          icon: "fa fa-cog",
          link: "/setting",
        },
      ];
    } else if (userRole === "user") {
      data = [
        {
          title: "Dashboard",
          icon: "fa fa-dashboard",
          link: "/athletedashboard",
        },
        {
          title: "Athlete Profile Edit",
          icon: "fa fa-pencil-square-o",
          link: "/athleteedit",
        },
        
      ];
    } else if (userRole === "super_administrator") {
      data = [
        {
          title: "Dashboard",
          icon: "fa fa-dashboard",
          link: "/dashboard",
        },
        {
          title: "Inbox",
          icon: "fa fa-envelope-o",
          link: "/inbox",
        },
        {
          title: "Account",
          icon: "fa fa-user-circle",
          link: "/accounts",
        },
        {
          title: "Colleges",
          icon: "fa fa-graduation-cap",
          link: "/college",
        },
        // {
        //   title: "Checklists",
        //   icon: "fa fa-list-alt",
        //   link: "/college",
        // },
        {
          title: "Settings",
          icon: "fa fa-cog",
          link: "/setting",
        },
      ];
    }

    setSidebarData(data);
  }, []);

  const handleLinkClick = (link) => {
    setActiveLink(link);
  };
  return (
    <div className="sidebar-wrapper">
      <div>
        <ul className="sidebar-links-wrapper">
          {sidebarData.map((item, index) => {
            return (
              <li
                key={index}
                className={`sidebar-link ${
                  activeLink === item.link ? "activeLink" : ""
                }`}
              >
                <Link to={item.link} onClick={() => handleLinkClick(item.link)}>
                  <i className={item.icon} aria-hidden="true"></i>
                  {"\u00A0"}
                  <p className="sidebar-title">{item.title}</p>
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
