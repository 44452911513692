// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./Assets/Fonts/CircularStd-Medium.woff", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap);"]);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: "Circular Std";
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("woff");
  }
  
*{
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}
img{
    max-width: 100%;
    height: auto;
}
p{
    margin: 0px;
}
ul{
    margin: 0px;
}
.overflw {
    overflow: hidden;
}

select{
    position: relative;
}
select::after{
    content: '\\f0dd';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    padding: 0 1em;
    background: #f0f0f0;
    pointer-events: none;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,2DAAiE;EACnE;;AAIF;IACI,WAAW;IACX,YAAY;IACZ,sBAAsB;AAC1B;AACA;IACI,eAAe;IACf,YAAY;AAChB;AACA;IACI,WAAW;AACf;AACA;IACI,WAAW;AACf;AACA;IACI,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;AACtB;AACA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,MAAM;IACN,QAAQ;IACR,SAAS;IACT,cAAc;IACd,mBAAmB;IACnB,oBAAoB;AACxB","sourcesContent":["@font-face {\n    font-family: \"Circular Std\";\n    src: url(\"./Assets/Fonts/CircularStd-Medium.woff\") format(\"woff\");\n  }\n  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');\n  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');\n  \n*{\n    margin: 0px;\n    padding: 0px;\n    box-sizing: border-box;\n}\nimg{\n    max-width: 100%;\n    height: auto;\n}\np{\n    margin: 0px;\n}\nul{\n    margin: 0px;\n}\n.overflw {\n    overflow: hidden;\n}\n\nselect{\n    position: relative;\n}\nselect::after{\n    content: '\\f0dd';\n    position: absolute;\n    top: 0;\n    right: 0;\n    bottom: 0;\n    padding: 0 1em;\n    background: #f0f0f0;\n    pointer-events: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
