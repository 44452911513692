// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar-wrapper {
  width: 15vw;
  background: #fff;
  margin-top: 30px;
  height: 80vh;
  border-radius: 16px;
  padding: 20px;
}
.sidebar-wrapper .sidebar-links-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0px;
}
.sidebar-wrapper .sidebar-links-wrapper .sidebar-link {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 20px;
  border-radius: 16px;
  transition: all 0.3s ease;
}
.sidebar-wrapper .sidebar-links-wrapper .sidebar-link a {
  display: flex;
  gap: 4px;
  text-decoration: none;
}
.sidebar-wrapper .sidebar-links-wrapper .sidebar-link a .sidebar-icon {
  font-size: 20px;
  color: #0291dd;
}
.sidebar-wrapper .sidebar-links-wrapper .sidebar-link a .sidebar-title {
  color: #3e3e3e;
  font-family: "Circular Std";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 0px;
}
.sidebar-wrapper .sidebar-links-wrapper .sidebar-link:hover {
  background: #f5f5f5;
}

@media (max-width: 1200px) {
  .sidebar-wrapper {
    display: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/Components/Sidebar/sidebar.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,gBAAA;EACA,gBAAA;EACA,YAAA;EACA,mBAAA;EACA,aAAA;AACF;AAAE;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,YAAA;AAEJ;AADI;EACE,aAAA;EACA,mBAAA;EACA,SAAA;EACA,kBAAA;EACA,mBAAA;EACA,yBAAA;AAGN;AAEM;EACE,aAAA;EACA,QAAA;EACA,qBAAA;AAAR;AACQ;EACE,eAAA;EACA,cAAA;AACV;AACQ;EACE,cAAA;EACA,2BAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,kBAAA;AACV;AAEM;EACE,mBAAA;AAAR;;AAOA;EACE;IACE,aAAA;EAJF;AACF","sourcesContent":[".sidebar-wrapper {\n  width: 15vw;\n  background: #fff;\n  margin-top: 30px;\n  height: 80vh;\n  border-radius: 16px;\n  padding: 20px;\n  .sidebar-links-wrapper {\n    display: flex;\n    flex-direction: column;\n    gap: 20px;\n    padding: 0px;\n    .sidebar-link {\n      display: flex;\n      align-items: center;\n      gap: 10px;\n      padding: 10px 20px;\n      border-radius: 16px;\n      transition: all 0.3s ease;\n      // &.activeLink{\n      //   background: #f5f5f5;\n      //   color: #0291dd;\n      // }\n      a {\n        display: flex;\n        gap: 4px;\n        text-decoration: none;\n        .sidebar-icon {\n          font-size: 20px;\n          color: #0291dd;\n        }\n        .sidebar-title {\n          color: #3e3e3e;\n          font-family: \"Circular Std\";\n          font-size: 16px;\n          font-style: normal;\n          font-weight: 500;\n          line-height: normal;\n          margin-bottom: 0px;\n        }\n      }\n      &:hover {\n        background: #f5f5f5;\n      }\n    }\n  }\n}\n\n\n@media (max-width: 1200px) {\n  .sidebar-wrapper{\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
