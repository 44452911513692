// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashpage-wrapper {
  padding: 20px 50px;
  background-color: #eee;
}
.dashpage-wrapper .dashpage-title {
  font-family: "Circular Std";
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 30px;
  color: #408ce5;
}

@media (max-width: 1000px) {
  .dashpage-wrapper {
    padding: 20px 20px;
  }
}`, "",{"version":3,"sources":["webpack://./src/Pages/Dashboard/DashHome/dashhome.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,sBAAA;AACF;AAAE;EACE,2BAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,gBAAA;EACA,cAAA;AAEJ;;AAEA;EACE;IACE,kBAAA;EACF;AACF","sourcesContent":[".dashpage-wrapper {\n  padding: 20px 50px;\n  background-color: #eee;\n  .dashpage-title {\n    font-family: \"Circular Std\";\n    font-size: 28px;\n    font-style: normal;\n    font-weight: 700;\n    line-height: normal;\n    margin-top: 30px;\n    color: #408ce5;\n  }\n \n}\n@media (max-width: 1000px) {\n  .dashpage-wrapper {\n    padding: 20px 20px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
