// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashhome-box {
  margin-top: 20px;
  padding: 30px;
  background: #fff;
  border-radius: 20px;
}
.dashhome-box .dashhome-box-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dashhome-box .dashhome-box-top .icon-wrap {
  width: 60px;
  height: 60px;
  background-color: rgba(66, 137, 226, 0.05);
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dashhome-box .dashhome-box-top .icon-wrap i {
  color: #408ce5;
  font-size: 22px;
}
.dashhome-box .dashhome-box-top .content-wrap h2 {
  font-family: "Circular Std";
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #408ce5;
  margin: 0px;
}
.dashhome-box .details-wrap {
  margin-top: 30px;
}
.dashhome-box .details-wrap h4 {
  color: #000;
  font-family: "Circular Std";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.dashhome-box .details-wrap p {
  color: #79909e;
  font-family: "Circular Std";
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
}`, "",{"version":3,"sources":["webpack://./src/Components/PageComponents/DashHomeBox/dashhomebox.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,aAAA;EACA,gBAAA;EACA,mBAAA;AACJ;AAAI;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;AAEN;AADM;EACE,WAAA;EACA,YAAA;EACA,0CAAA;EACA,mBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AAGR;AAFQ;EACE,cAAA;EACA,eAAA;AAIV;AAAQ;EACE,2BAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,cAAA;EACA,WAAA;AAEV;AAEI;EACI,gBAAA;AAAR;AACM;EACE,WAAA;EACA,2BAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AACR;AACM;EACE,cAAA;EACA,2BAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AACR","sourcesContent":[".dashhome-box {\n    margin-top: 20px;\n    padding: 30px;\n    background: #fff;\n    border-radius: 20px;\n    .dashhome-box-top {\n      display: flex;\n      justify-content: space-between;\n      align-items: center;\n      .icon-wrap {\n        width: 60px;\n        height: 60px;\n        background-color: rgba(66, 137, 226, 0.05);\n        border-radius: 30px;\n        display: flex;\n        justify-content: center;\n        align-items: center;\n        i {\n          color: #408ce5;\n          font-size: 22px;\n        }\n      }\n      .content-wrap {\n        h2 {\n          font-family: \"Circular Std\";\n          font-size: 48px;\n          font-style: normal;\n          font-weight: 700;\n          line-height: normal;\n          color: #408ce5;\n          margin: 0px;\n        }\n      }\n    }\n    .details-wrap {\n        margin-top: 30px;\n      h4 {\n        color: #000;\n        font-family: \"Circular Std\";\n        font-size: 24px;\n        font-style: normal;\n        font-weight: 700;\n        line-height: normal;\n      }\n      p {\n        color: #79909e;\n        font-family: \"Circular Std\";\n        font-size: 16px;\n        font-style: normal;\n        font-weight: 450;\n        line-height: normal;\n      }\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
