import { combineReducers } from "redux";
import userInfoReducer from "./userInfoReducer";
import athleteInfoReducer from "./athleteInfoProfileReducer";

const rootReducer = combineReducers({
  userInfo: userInfoReducer,
  userProfiles: athleteInfoReducer
});

export default rootReducer;
