import React, { useState, useEffect } from "react";
import axios from "axios";
import base from '../../../Config/config';
import { useImpersonation } from "../../../contexts/ImpersonationContext";
import "../College/college.scss";
import "./accountspage.scss";
// import userAvatar from "../../../Assets/Images/user-avatar.png";
import collegeLogo1 from "../../../Assets/Images/uni-logo.jpg";
import collegeLogo2 from "../../../Assets/Images/uni-logo2.png";
import collegeLogo3 from "../../../Assets/Images/uni-logo3.png";
import Navbar from "../../../Components/Navbar/Navbar";
import Sidebar from "../../../Components/Sidebar/sidebar";
import { Link } from "react-router-dom";
import { CircularProgress } from "@mui/material";

const AccountsPage = () => {
  const { isImpersonating, handleImpersonate, handleStopImpersonating } =
    useImpersonation();
  const [activeTab, setActiveTab] = useState("tab1");
  const [loading, setLoading] = useState(true);
  const [ProfilesData, setProfileData] = useState([]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const token = localStorage.getItem("token");
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        const response = await axios.get(
          `${base.BASE_URL}/admin/coaches/getAllUsers`,
          config
        );
        console.log(response.data.data.users);
        setProfileData(response.data.data.users);
      } catch (error) {
        console.error("Error fetching profile data:", error);
      } finally {
        setLoading(false);
        
      }
    };

    fetchUser();
  }, []);

  if (loading) {
    return (
      <div
        className="loader-container d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="college-wrapper">
      <div className="container-fluid">
        <Navbar />
        <div className="row">
          <div className="col-lg-2">
            <Sidebar />
          </div>
          <div className="col-lg-10">
            <div className="filteration-wrapper">
              <h4 className="collegepage-title">Search Colleges</h4>
              <div className="tabs-wrap">
                <div className="row">
                  <div className="col-lg-1">
                    <div className="tab-box">
                      <div
                        className={`tab ${
                          activeTab === "tab1" ? "active" : ""
                        }`}
                      >
                        <input
                          type="radio"
                          id="tab1"
                          name="tab"
                          onClick={() => handleTabClick("tab1")}
                        />
                        <label htmlFor="tab1">Athletes</label>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-1">
                    <div className="tab-box">
                      <div
                        className={`tab ${
                          activeTab === "tab2" ? "active" : ""
                        }`}
                      >
                        <input
                          type="radio"
                          id="tab2"
                          name="tab"
                          onClick={() => handleTabClick("tab2")}
                        />
                        <label htmlFor="tab2">Teams</label>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-1">
                    <div className="tab-box">
                      <div
                        className={`tab ${
                          activeTab === "tab3" ? "active" : ""
                        }`}
                      >
                        <input
                          type="radio"
                          id="tab3"
                          name="tab"
                          onClick={() => handleTabClick("tab3")}
                        />
                        <label htmlFor="tab3">Admins</label>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-1">
                    <div className="tab-box">
                      <div
                        className={`tab ${
                          activeTab === "tab4" ? "active" : ""
                        }`}
                      >
                        <input
                          type="radio"
                          id="tab4"
                          name="tab"
                          onClick={() => handleTabClick("tab3")}
                        />
                        <label htmlFor="tab4">Settings</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`tab-content ${
                  activeTab === "tab1" ? "active" : ""
                }`}
              >
                <div className="filter-wrap">
                  <div className="row">
                    <div className="col-lg-1">
                      <div className="filter-box form-group">
                        <select className="filter-select form-control">
                          <option value="select" className="first">
                            Sports
                          </option>
                          <option value="btech">B.Tech</option>
                          <option value="mtech">M.Tech</option>
                          <option value="bca">BCA</option>
                          <option value="mca">MCA</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-1">
                      <div className="filter-box form-group">
                        <select className="filter-select form-control">
                          <option value="select" className="first">
                            Davison
                          </option>
                          <option value="cse">CSE</option>
                          <option value="ece">ECE</option>
                          <option value="mech">Mechanical</option>
                          <option value="civil">Civil</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-2">
                      <div className="filter-box">
                        <input
                          type="text"
                          className="filter-input form-control"
                          placeholder="Conference"
                        />
                      </div>
                    </div>
                    <div className="col-lg-2">
                      <div className="filter-box form-group">
                        <select className="filter-select form-control">
                          <option value="select" className="first">
                            Fields of study
                          </option>
                          <option value="cse">CSE</option>
                          <option value="ece">ECE</option>
                          <option value="mech">Mechanical</option>
                          <option value="civil">Civil</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-2">
                      <div className="filter-box form-group">
                        <select className="filter-select form-control">
                          <option value="select" className="first">
                            Type of school
                          </option>
                          <option value="cse">CSE</option>
                          <option value="ece">ECE</option>
                          <option value="mech">Mechanical</option>
                          <option value="civil">Civil</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-2">
                      <div className="filter-box form-group">
                        <select className="filter-select form-control">
                          <option value="select" className="first">
                            Locale
                          </option>
                          <option value="cse">CSE</option>
                          <option value="ece">ECE</option>
                          <option value="mech">Mechanical</option>
                          <option value="civil">Civil</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-2">
                      <div className="filter-box form-group">
                        <select className="filter-select form-control">
                          <option value="select" className="first">
                            Religious Affiliations
                          </option>
                          <option value="cse">CSE</option>
                          <option value="ece">ECE</option>
                          <option value="mech">Mechanical</option>
                          <option value="civil">Civil</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">Name</th>
                          <th scope="col">Phone no</th>
                          <th scope="col">Status</th>
                          <th scope="col"></th>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {ProfilesData.map((card) => {
                          {
                            console.log(card);
                          }
                          return (
                            <tr>
                              <td>
                                <div className="uni-det">
                                  <div className="uni-img">
                                    <img src={card.profilePicture} alt="" />
                                  </div>
                                  <p>
                                    {card.username}
                                    <br />
                                    <span>{card.email}</span>
                                  </p>
                                </div>
                              </td>
                              <td>
                                <p className="sport">{card.phoneNumber}</p>
                              </td>
                              <td>
                                <p className="status">{card.status}</p>
                              </td>

                              <td
                                onClick={() =>
                                  handleImpersonate(
                                    card.userId,
                                    card.username,
                                    card.role
                                  )
                                }
                              >
                                <p className="impression">
                                  <i
                                    className="fa fa-sign-out"
                                    aria-hidden="true"
                                  ></i>
                                  <span>Impersonate</span>
                                </p>
                              </td>

                              <td>
                                <button className="btn btn-a upload">
                                  <i
                                    className="fa fa-upload "
                                    aria-hidden="true"
                                  ></i>
                                </button>
                                <button className="btn btn-a delete">
                                  <i className="fa fa-trash" aria-hidden="true"></i>
                                </button>
                                <button className="btn btn-a setting">
                                  <i className="fa fa-cog" aria-hidden="true"></i>
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                        {/* <tr>
                          <th scope="row">1</th>
                          <td>Mark</td>
                          <td>Otto</td>
                          <td>@mdo</td>
                        </tr>
                        <tr>
                          <th scope="row">2</th>
                          <td>Jacob</td>
                          <td>Thornton</td>
                          <td>@fat</td>
                        </tr>
                        <tr>
                          <th scope="row">3</th>
                          <td>Larry</td>
                          <td>the Bird</td>
                          <td>@twitter</td>
                        </tr> */}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div
                className={`tab-content ${
                  activeTab === "tab2" ? "active" : ""
                }`}
              >
                <div className="filter-wrap">
                  <div className="row">
                    <div className="col-lg-1">
                      <div className="filter-box form-group">
                        <select className="filter-select form-control">
                          <option value="select" className="first">
                            Sports
                          </option>
                          <option value="btech">B.Tech</option>
                          <option value="mtech">M.Tech</option>
                          <option value="bca">BCA</option>
                          <option value="mca">MCA</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-1">
                      <div className="filter-box form-group">
                        <select className="filter-select form-control">
                          <option value="select" className="first">
                            Davison
                          </option>
                          <option value="cse">CSE</option>
                          <option value="ece">ECE</option>
                          <option value="mech">Mechanical</option>
                          <option value="civil">Civil</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-2">
                      <div className="filter-box">
                        <input
                          type="text"
                          className="filter-input form-control"
                          placeholder="Conference"
                        />
                      </div>
                    </div>
                    <div className="col-lg-2">
                      <div className="filter-box form-group">
                        <select className="filter-select form-control">
                          <option value="select" className="first">
                            Fields of study
                          </option>
                          <option value="cse">CSE</option>
                          <option value="ece">ECE</option>
                          <option value="mech">Mechanical</option>
                          <option value="civil">Civil</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-2">
                      <div className="filter-box form-group">
                        <select className="filter-select form-control">
                          <option value="select" className="first">
                            Type of school
                          </option>
                          <option value="cse">CSE</option>
                          <option value="ece">ECE</option>
                          <option value="mech">Mechanical</option>
                          <option value="civil">Civil</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-2">
                      <div className="filter-box form-group">
                        <select className="filter-select form-control">
                          <option value="select" className="first">
                            Locale
                          </option>
                          <option value="cse">CSE</option>
                          <option value="ece">ECE</option>
                          <option value="mech">Mechanical</option>
                          <option value="civil">Civil</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-2">
                      <div className="filter-box form-group">
                        <select className="filter-select form-control">
                          <option value="select" className="first">
                            Religious Affiliations
                          </option>
                          <option value="cse">CSE</option>
                          <option value="ece">ECE</option>
                          <option value="mech">Mechanical</option>
                          <option value="civil">Civil</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <h1>jhhg2</h1>
                  </div>
                </div>
              </div>

              <div
                className={`tab-content ${
                  activeTab === "tab3" ? "active" : ""
                }`}
              >
                <div className="filter-wrap">
                  <div className="row">
                    <div className="col-lg-1">
                      <div className="filter-box form-group">
                        <select className="filter-select form-control">
                          <option value="select" className="first">
                            Sports
                          </option>
                          <option value="btech">B.Tech</option>
                          <option value="mtech">M.Tech</option>
                          <option value="bca">BCA</option>
                          <option value="mca">MCA</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-1">
                      <div className="filter-box form-group">
                        <select className="filter-select form-control">
                          <option value="select" className="first">
                            Davison
                          </option>
                          <option value="cse">CSE</option>
                          <option value="ece">ECE</option>
                          <option value="mech">Mechanical</option>
                          <option value="civil">Civil</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-2">
                      <div className="filter-box">
                        <input
                          type="text"
                          className="filter-input form-control"
                          placeholder="Conference"
                        />
                      </div>
                    </div>
                    <div className="col-lg-2">
                      <div className="filter-box form-group">
                        <select className="filter-select form-control">
                          <option value="select" className="first">
                            Fields of study
                          </option>
                          <option value="cse">CSE</option>
                          <option value="ece">ECE</option>
                          <option value="mech">Mechanical</option>
                          <option value="civil">Civil</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-2">
                      <div className="filter-box form-group">
                        <select className="filter-select form-control">
                          <option value="select" className="first">
                            Type of school
                          </option>
                          <option value="cse">CSE</option>
                          <option value="ece">ECE</option>
                          <option value="mech">Mechanical</option>
                          <option value="civil">Civil</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-2">
                      <div className="filter-box form-group">
                        <select className="filter-select form-control">
                          <option value="select" className="first">
                            Locale
                          </option>
                          <option value="cse">CSE</option>
                          <option value="ece">ECE</option>
                          <option value="mech">Mechanical</option>
                          <option value="civil">Civil</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-2">
                      <div className="filter-box form-group">
                        <select className="filter-select form-control">
                          <option value="select" className="first">
                            Religious Affiliations
                          </option>
                          <option value="cse">CSE</option>
                          <option value="ece">ECE</option>
                          <option value="mech">Mechanical</option>
                          <option value="civil">Civil</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <h1>jhhg3</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountsPage;
