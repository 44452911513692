import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./athlete.scss";
import axios from "axios";
import base from '../../../Config/config';
import { getUserInfo } from "../../../store/actions/userInfoAction";
import Navbar from "../../../Components/Navbar/Navbar";
import Sidebar from "../../../Components/Sidebar/sidebar";
import Footer from "../../../Components/Footer/Footer";
import LatestNews from "../../../Components/PageComponents/LatestNews/LatestNews";
import DashHomeBox from "../../../Components/PageComponents/DashHomeBox/DashHomeBox";
import DiscoverNews from "../../../Components/PageComponents/DiscoverNews/DiscoverNews";
import AthleteProfileBanner from "../../../Components/PageComponents/AthleteProfileBanner/AthleteProfileBanner";

import { Modal, CircularProgress } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";

const AthleteHome = () => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  const dispatch = useDispatch();
  const { user, error } = useSelector((state) => state.userInfo);

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getUserInfo());
      setLoading(false);
    };

    fetchData();
  }, [dispatch]);

  useEffect(() => {
    if (!loading && user && !user.profile) {
      setOpen(true);
    }
  }, [loading, user]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrorMessage(""); // Reset error message
    try {
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.post(
        `${base.BASE_URL}/users/createProfile`,
        {
          gpa: event.target.gpa.value,
          intendedMajor: event.target.IntendedMajor.value,
          height: event.target.Height.value,
          weight: event.target.Weight.value,
          birthDate: event.target.BirthDay.value,
          satScore: event.target.SatScore.value,
          actScore: event.target.ActScore.value,
          location: event.target.Location.value,
          personalStatement: event.target.PersonalStatement.value,
          school: event.target.School.value,
        },
        config
      );

      if (response.data.code === "UNKNOWN_ERROR") {
        toast.warn(response.data.message);
      } else {
        toast.success("Profile created successfully");
        setOpen(false); // Close modal only on success
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setErrorMessage("An error occurred. Please try again.");
    }
  };

  if (loading) {
    return (
      <div
        className="loader-container d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <ToastContainer />
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      <ToastContainer />
      <div className="dashpage-wrapper">
        <div className="container-fluid">
          <Navbar />
          <div className="row">
            <div className="col-lg-2">
              <Sidebar />
            </div>
            <div className="col-lg-7">
              <AthleteProfileBanner />
              <h4 className="dashpage-title">Dashboard</h4>
              <div className="row">
                <DashHomeBox />
              </div>
              <div className="row">
                <DiscoverNews />
              </div>
            </div>
            <div className="col-lg-3">
              <LatestNews />
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <Modal
        open={open}
        onClose={() => {}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal-wrapper"
        BackdropProps={{ style: { pointerEvents: "none" } }}
        disableEscapeKeyDown
      >
        <div className="modal-content">
          <div className="modal-head">
            <i className="fa fa-user" aria-hidden="true"></i>
            <h2 id="modal-modal-title" className="modal-title">
              Profile Information
            </h2>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="gpa">GPA</label>
              <input
                type="text"
                className="form-control"
                id="gpa"
                required
                pattern="^[0-4](\.\d{1,2})?$"
                title="GPA should be a number between 0.0 and 4.0"
              />
            </div>
            <div className="form-group">
              <label htmlFor="IntendedMajor">Intended Major</label>
              <input
                type="text"
                className="form-control"
                id="IntendedMajor"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="Height">Height</label>
              <input
                type="text"
                className="form-control"
                id="Height"
                required
                pattern="^\d+(\.\d+)?$"
                title="Height should be a valid number"
              />
            </div>

            <div className="form-group">
              <label htmlFor="Weight">Weight</label>
              <input
                type="text"
                className="form-control"
                id="Weight"
                required
                pattern="^\d+(\.\d+)?$"
                title="Weight should be a valid number"
              />
            </div>

            <div className="form-group">
              <label htmlFor="BirthDay">Birth Day</label>
              <input
                type="date"
                className="form-control"
                id="BirthDay"
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="SatScore">Sat Score</label>
              <input
                type="text"
                className="form-control"
                id="SatScore"
                required
                pattern="^\d+$"
                title="SAT Score should be a valid number"
              />
            </div>
            <div className="form-group">
              <label htmlFor="ActScore">Act Score</label>
              <input
                type="text"
                className="form-control"
                id="ActScore"
                required
                pattern="^\d+$"
                title="ACT Score should be a valid number"
              />
            </div>
            <div className="form-group">
              <label htmlFor="Location">Location</label>
              <input
                type="text"
                className="form-control"
                id="Location"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="PersonalStatement">Personal Statement</label>
              <input
                type="text"
                className="form-control"
                id="PersonalStatement"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="School">School</label>
              <input
                type="text"
                className="form-control"
                id="School"
                required
              />
            </div>

            <button type="submit" className="modal-submit-btn">
              Submit
            </button>
            {errorMessage && <p className="error-message">{errorMessage}</p>}
          </form>
        </div>
      </Modal>
    </>
  );
};

export default AthleteHome;
