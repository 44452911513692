// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashhome-right {
  margin-top: 20px;
  background: #fff;
  padding: 20px;
  border-radius: 15px;
}
.dashhome-right .dashhome-right-wrap h4 {
  color: #091b3d;
  font-family: "Circular Std";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 155.556% */
}
.dashhome-right .dashhome-right-wrap .recent-activities .activity-wrapper {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
}
.dashhome-right .dashhome-right-wrap .recent-activities .activity-wrapper p {
  color: #091b3d;
  font-family: "Circular Std";
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: 22px; /* 157.143% */
  margin: 0px;
}
.dashhome-right .dashhome-right-wrap .recent-activities .activity-wrapper p a {
  color: #0291dd;
  padding: 0px 5px;
}`, "",{"version":3,"sources":["webpack://./src/Components/PageComponents/LatestNews/latestnews.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,gBAAA;EACA,aAAA;EACA,mBAAA;AACJ;AACM;EACE,cAAA;EACA,2BAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,aAAA;AACR;AAEQ;EACI,gBAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,SAAA;AAAZ;AAEU;EACE,cAAA;EACA,2BAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,aAAA;EACA,WAAA;AAAZ;AACY;EACE,cAAA;EACA,gBAAA;AACd","sourcesContent":[".dashhome-right {\n    margin-top: 20px;\n    background: #fff;\n    padding: 20px;\n    border-radius: 15px;\n    .dashhome-right-wrap {\n      h4 {\n        color: #091b3d;\n        font-family: \"Circular Std\";\n        font-size: 18px;\n        font-style: normal;\n        font-weight: 700;\n        line-height: 28px; /* 155.556% */\n      }\n      .recent-activities {\n        .activity-wrapper {\n            margin-top: 10px;\n            display: flex;\n            justify-content: space-between;\n            align-items: center;\n            gap: 15px;\n        \n          p {\n            color: #091b3d;\n            font-family: \"Circular Std\";\n            font-size: 14px;\n            font-style: normal;\n            font-weight: 450;\n            line-height: 22px; /* 157.143% */\n            margin: 0px;\n            a {\n              color: #0291dd;\n              padding: 0px 5px;\n            }\n          }\n        }\n      }\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
