import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUserInfo } from "../store/actions/userInfoAction";
import LoginForm from "../Pages/LoginForm/LoginForm";
import SignupForm from "../Pages/SignupForm/SignupForm";
import ForgotPassword from "../Pages/ForgotPassword/ForgotPassword";
// import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import DashHome from "../Pages/Dashboard/DashHome/DashHome";
import College from "../Pages/Dashboard/College/College";
import CollegeDetail from "../Pages/Dashboard/CollegeDetail/CollegeDetail";
import AccountsPage from "../Pages/Dashboard/AccountsPage/AccountsPage";
import AthleteHome from "../Pages/Dashboard/AthleteHome/AthleteHome";
import AthleteEditProfile from "../Pages/Dashboard/AthleteEditProfile/AthleteEditProfile";
import AthleteProfile from "../Pages/Dashboard/AthleteProfile/AthleteProfile";
import CollegeEdit from "../Pages/Dashboard/CollegeEdit/CollegeEdit";

export default function Navigation() {
  const [userRole, setUserRole] = useState(localStorage.getItem("role") || "");

  useEffect(() => {
    const handleStorageChange = () => {
      const role = localStorage.getItem("role");
      setUserRole(role);
    };
    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const isLoggedIn = () => {
    const user_found = localStorage.getItem("token");
    return !!user_found;
  };


  const isRouteAllowed = (role, path) => {
    const roleRoutes = {
      collegeCoach: [
        "/",
        "/signup",
        "/forgotpassword",
        "/dashboard",
        "/college",
      ],
      highSchoolCoach: [
        "/",
        "/signup",
        "/forgotpassword",
        "/dashboard",
        "/college",
      ],
      user: [
        "/",
        "/signup",
        "/forgotpassword",
        "/athletedashboard",
        "/athleteedit",
        "/athleteprofile"
      ],
      super_administrator: [
        "/",
        "/signup",
        "/forgotpassword",
        "/dashboard",
        "/college",
        "/college/:collegeId",
        "/collegeedit/:collegeId",
        "/accounts",
      ],
    };

    return roleRoutes[role] && roleRoutes[role].includes(path);
  };

  // const ProtectedRoute = ({ element, path }) => {
  //   return isLoggedIn() && isRouteAllowed(userRole, path) ? (
  //     element
  //   ) : (
  //     <Navigate to="/" replace state={{ from: path }} />
  //   );
  // };

  const ProtectedRoute = ({ element, path }) => {
    const location = useLocation();
    return isLoggedIn() && isRouteAllowed(userRole, path) ? (
      element
    ) : (
      <Navigate to="/" replace state={{ from: location }} />
    );
  };

  const RedirectIfLoggedIn = ({ element }) => {
    const loggedIn = isLoggedIn();
    return loggedIn ? (
      userRole === "user" ? (
        <Navigate to="/athletedashboard" replace />
      ) : (
        <Navigate to="/dashboard" replace />
      )
    ) : (
      element
    );
  };

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={<RedirectIfLoggedIn element={<LoginForm />} />}
        />
        <Route
          path="/signup"
          element={<RedirectIfLoggedIn element={<SignupForm />} />}
        />
        <Route
          path="/forgotpassword"
          element={<RedirectIfLoggedIn element={<ForgotPassword />} />}
        />
        <Route
          path="/dashboard"
          element={<ProtectedRoute element={<DashHome />} path="/dashboard" />}
        />
        <Route
          path="/college"
          element={<ProtectedRoute element={<College />} path="/college" />}
        />
        <Route
          path="/college/:collegeId"
          element={
            <ProtectedRoute element={<CollegeDetail />} path="/college/:collegeId" />
          }
        />
        <Route
          path="/collegeedit/:collegeId"
          element={
            <ProtectedRoute element={<CollegeEdit />} path="/college/:collegeId" />
          }
        />
        <Route
          path="/accounts"
          element={
            <ProtectedRoute element={<AccountsPage />} path="/accounts" />
          }
        />
        <Route
          path="/athletedashboard"
          element={
            <ProtectedRoute
              element={<AthleteHome />}
              path="/athletedashboard"
            />
          }
        />
        <Route
          path="/athleteedit"
          element={
            <ProtectedRoute
              element={<AthleteEditProfile />}
              path="/athleteedit"
            />
          }
        />
         <Route
          path="/athleteprofile/:profileId"
          element={
            <ProtectedRoute
              element={<AthleteProfile />}
              path="/athleteprofile"
            />
          }
        />
        <Route path="*" element={<Navigate to="/" />} />
        {/* <Route path="/accounts" element={<AccountsPage />} />
        <Route path="/dashboard" element={<DashHome />} />
        <Route path="/college" element={<College />} />
        <Route path="/college/:collegeId" element={<CollegeDetail />} /> */}
      </Routes>
    </Router>
  );
}
