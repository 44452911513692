import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import base from '../../../Config/config';
import { getUserInfo } from "../../../store/actions/userInfoAction";
import { getProfileInfo } from "../../../store/actions/athleteInfoProfilesAction";
import holoicon from "../../../Assets/Images/holo-person-icon.svg";
import Navbar from "../../../Components/Navbar/Navbar";
import Sidebar from "../../../Components/Sidebar/sidebar";
import Footer from "../../../Components/Footer/Footer";
import { Link } from "react-router-dom";
import { Modal, CircularProgress } from "@mui/material";

import "./athleteeditprofile.scss";
import AthleteAddProfileBanner from "../../../Components/PageComponents/AthleteAddProfileBanner/AthleteAddProfileBanner";

const AthleteEditProfile = () => {
  const [open, setOpen] = useState(false);
  
  const [loading, setLoading] = useState(true); // Add loading state

  const [selectedSport, setSelectedSport] = useState("");
  const [primaryPositions, setPrimaryPositions] = useState([]);
  const [secondaryPositions, setSecondaryPositions] = useState([]);
  const [selectedPrimaryPosition, setSelectedPrimaryPosition] = useState("");
  const [selectedSecondaryPosition, setSelectedSecondaryPosition] =
    useState("");

  const handleOpen = () => {
    setOpen(true);
  };

  // const handleClose = () => {
  //   setOpen(false);
  // };

  const dispatch = useDispatch();
  const {
    user,
    loading: userLoading,
    error,
  } = useSelector((state) => state.userInfo);
  const { profileInfo, loadingprofile, errorprofile } = useSelector(
    (state) => state.userProfiles
  );

  useEffect(() => {
    dispatch(getUserInfo());
    dispatch(getProfileInfo());
  }, [dispatch]);




  const sportsPositions = {
    Baseball: [
      "Pitcher",
      "Catcher",
      "First Base",
      "Second Base",
      "Shortstop",
      "Third Base",
      "Outfield",
    ],
    "Beach Volleyball": ["Defender", "Blocker"],
    Bowling: ["Bowler"],
    Cheerleading: ["Base", "Flyer", "Back Spot"],
    "Cross Country": ["Runner"],
    Dance: ["Dancer"],
    Esports: ["Player", "Coach"],
    "Field Hockey": ["Goalkeeper", "Defender", "Midfielder", "Forward"],
    Football: [
      "Quarterback",
      "Running Back",
      "Wide Receiver",
      "Tight End",
      "Offensive Line",
      "Defensive Line",
      "Linebacker",
      "Cornerback",
      "Safety",
    ],
    Golf: ["Golfer"],
    Gymnastics: ["Vault", "Uneven Bars", "Balance Beam", "Floor Exercise"],
    "Ice Hockey": ["Goalie", "Defenseman", "Center", "Winger"],
    Lacrosse: ["Attack", "Midfield", "Defense", "Goalie"],
    Rowing: ["Coxswain", "Rower"],
    Rugby: [
      "Prop",
      "Hooker",
      "Lock",
      "Flanker",
      "Number 8",
      "Scrum-half",
      "Fly-half",
      "Center",
      "Wing",
      "Fullback",
    ],
    Soccer: ["Goalkeeper", "Defender", "Midfielder", "Forward"],
    Softball: [
      "Pitcher",
      "Catcher",
      "First Base",
      "Second Base",
      "Shortstop",
      "Third Base",
      "Outfield",
    ],
    "Swimming & Diving": ["Swimmer", "Diver"],
    Tennis: ["Singles", "Doubles"],
    Track: [
      "Sprinter",
      "Middle Distance",
      "Long Distance",
      "Hurdles",
      "Relay",
      "Jumping",
      "Throwing",
    ],
    Volleyball: [
      "Setter",
      "Outside Hitter",
      "Opposite Hitter",
      "Middle Blocker",
      "Libero",
    ],
    "Water polo": ["Goalkeeper", "Field Player"],
    Wrestling: ["Lightweight", "Middleweight", "Heavyweight"],
  };

  const handleSportChange = (event) => {
    const sport = event.target.value;
    setSelectedSport(sport);
    const positions = sportsPositions[sport] || [];
    setPrimaryPositions(positions);
    setSecondaryPositions(positions);
    setSelectedPrimaryPosition("");
    setSelectedSecondaryPosition("");
  };

  const handlePrimaryPositionChange = (event) => {
    setSelectedPrimaryPosition(event.target.value);
  };

  const handleSecondaryPositionChange = (event) => {
    setSelectedSecondaryPosition(event.target.value);
  };

  const getFilteredSecondaryPositions = () => {
    return secondaryPositions.filter(
      (position) => position !== selectedPrimaryPosition
    );
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const data = {
        sport: selectedSport,
        athletics: {
          primaryPosition: selectedPrimaryPosition,
          secondaryPosition: selectedSecondaryPosition,
        },
      };

      await axios.post(
        `${base.BASE_URL}/sportProfile/create`,
        data,
        config
      );
      console.log("Profile created successfully");
      dispatch(getProfileInfo());
    } catch (error) {
      console.error("Profile creation error:", error);
    } finally {
      setLoading(false);
      setOpen(false);
    }
  };

  const handleDelete = async (profid) => {
    console.log(profid);
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios.delete(
        `${base.BASE_URL}/sportProfile/delete`,
        {
          ...config,
          data: {
            sportProfileId: profid,
          },
        }
      );
      console.log("Profile deleted successfully", response.data);
      dispatch(getProfileInfo());
    } catch (error) {
      console.error("Profile deletion error:", error);
    } finally {
      setLoading(false);
    }
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <div className="editathlete">
      <div className="dashpage-wrapper ">
        <div className="container-fluid">
          <Navbar />
          <div className="row">
            <div className="col-lg-2">
              <Sidebar />
            </div>
            <div className="col-lg-10">
              {userLoading || loadingprofile ? (
                <div
                  className="loader-container d-flex justify-content-center align-items-center"
                  style={{ height: "100vh" }}
                >
                  <CircularProgress />
                </div>
              ) : (
                <>
                  <AthleteAddProfileBanner />
                  <section className="profile-info">
                    <div className="profile-info-head">
                      <p>My Profile</p>
                    </div>
                    <div className="info-wrapper">
                      <div className="info-wrapper-head">
                        <i className="fa fa-user" aria-hidden="true"></i>
                        <p>Profile Information</p>
                      </div>
                      <div className="info-wrapper-content">
                        <div className="row">
                          <div className="col-lg-4">
                            <div className="info-detail">
                              <h4>Your Name</h4>
                              <p>{user ? user.username : "test"}</p>
                            </div>

                            <div className="info-detail">
                              <h4>School</h4>
                              <p>{user ? user.profile?.school : "school"}</p>
                            </div>

                            <div className="info-detail">
                              <h4>Intended Major</h4>
                              <p>
                                {user ? user.profile?.intendedMajor : "N/A"}
                              </p>
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="info-detail">
                              <h4>GPA</h4>

                              <p>
                                {user
                                  ? user.profile
                                    ? user.profile.gpa
                                    : "gpa"
                                  : "gpa"}
                              </p>
                            </div>
                            <div className="info-detail">
                              <h4>Sat Score</h4>
                              <p>
                                {user ? user.profile?.satScore : "sat score"}
                              </p>
                            </div>
                            <div className="info-detail">
                              <h4>Act Score</h4>
                              <p>
                                {user
                                  ? user.profile?.actScore
                                  : "user act sore"}
                              </p>
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="info-detail">
                              <h4>Birth Day</h4>
                              {/* <p>
                                {user ? user.profile?.birthDate : "dd-mm-yy"}
                              </p> */}
                              <p>{user?.profile && formatDate(user.profile.birthDate)}</p>
                            </div>
                            <div className="info-detail">
                              <h4>Location</h4>
                              <p>{user ? user.profile?.location : "locate"}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="edit-profile-btn">
                      <button onClick={handleOpen}>
                        <Link>Add New</Link>
                        <i className="fa fa-arrows-h" aria-hidden="true"></i>
                      </button>
                    </div>
                  </section>

                  <section className="profile-info mt-5">
                    <div className="info-wrapper">
                      <div className="info-wrapper-head">
                        <img src={holoicon} alt="" />
                        <p>Your Online Athlete Profile</p>
                      </div>
                      <div className="info-wrapper-content2">
                        <div className="row">
                          {profileInfo?.map((content) => {
                            return (
                              <div className="profile-row-wrapper">
                                <div className="profile-img-name">
                                  {content?.img ? (
                                    <img src={content.img} alt="" />
                                  ) : (
                                    <img
                                      src="https://cdn-icons-png.flaticon.com/512/9131/9131529.png"
                                      alt=""
                                    />
                                  )}
                                  <h4>{content.user?.username}</h4>
                                </div>
                                <div className="profile-player">
                                  <p>
                                    Profile <span>{content.sport}</span>
                                  </p>
                                </div>
                                <div className="player-edit">
                                  <button>
                                    <i
                                      className="fa fa-pencil-square-o"
                                      aria-hidden="true"
                                    ></i>
                                    <Link to={`/athleteprofile/${content.sportProfileId}`}>
                                      Edit Profile
                                    </Link>
                                  </button>
                                </div>
                                <div className="copy-link">
                                  <button>
                                    <i
                                      className="fa fa-link"
                                      aria-hidden="true"
                                    ></i>
                                    <Link to="">Copy Link</Link>
                                  </button>
                                </div>
                                <div className="profile-veiw">
                                  <button>
                                    <i
                                      className="fa fa-eye"
                                      aria-hidden="true"
                                    ></i>
                                    <Link to={`/athleteprofile/${content.sportProfileId}`}>View Profile</Link>
                                  </button>
                                </div>
                                <div className="delete-profile">
                                  <button
                                    onClick={() =>
                                      handleDelete(content?.sportProfileId)
                                    }
                                  >
                                    <i
                                      className="fa fa-trash"
                                      aria-hidden="true"
                                    ></i>
                                    <p >Delete Profile</p>
                                  </button>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </section>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal-wrapper"
        Backdrop="static"
      >
        <div className="modal-content">
          <div className="modal-head">
            <i className="fa fa-user" aria-hidden="true"></i>
            <h2 id="modal-modal-title" className="modal-title">
              Create New Profile
            </h2>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="sport">What sport is this profile for?</label>
              <select
                className="form-control"
                name="sport"
                onChange={handleSportChange}
                value={selectedSport}
              >
                <option value="">Select Sports</option>
                {Object.keys(sportsPositions).map((sport) => (
                  <option key={sport} value={sport}>
                    {sport}
                  </option>
                ))}
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="primary">What is your primary position?</label>
              <select
                className="form-control"
                name="primary"
                onChange={handlePrimaryPositionChange}
                value={selectedPrimaryPosition}
              >
                <option value="">Select One</option>
                {primaryPositions.map((position) => (
                  <option key={position} value={position}>
                    {position}
                  </option>
                ))}
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="secondary">
                What is your secondary position?
              </label>
              <select
                className="form-control"
                name="secondary"
                onChange={handleSecondaryPositionChange}
                value={selectedSecondaryPosition}
              >
                <option value="">Select One</option>
                {getFilteredSecondaryPositions().map((position) => (
                  <option key={position} value={position}>
                    {position}
                  </option>
                ))}
              </select>
            </div>
            <button type="submit" className="modal-submit-btn">
              Submit
            </button>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default AthleteEditProfile;
